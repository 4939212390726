import { logEvent } from 'firebase/analytics';
import { useContext } from 'react';
import { analytics } from '../../App';
import '../../App.scss';
import { useDotAudioContext } from '../../contexts/DotAudioContext/DotAudioContextCustomHook';
import { ColorPalette, DotDataContext } from '../../contexts/DotDataContext/DotDataContext';
import { DotNavigationContext, View } from '../../contexts/NavigationContext/NavigationContext';
import { AboutSection } from '../../page-components/AboutPage/AboutPage';
import './Header.scss';

export const HeaderComponent = (props: any) => {
  const {view, setView} = useContext(DotNavigationContext);
  const {settingsState, setSettingsState} = useContext(DotDataContext);
  const {audioState} = useDotAudioContext();

  const openMusicDialog = () => {
    props.musicDialogOpenedCallback();
  }

  const openSoundsDialog = () => {
    props.soundDialogOpenedCallback();
  }

  const dotLogoHovered = () => {
    const ripple1 = document.getElementById("dot-logo-ripple-1");
    const ripple2 = document.getElementById("dot-logo-ripple-2");
    const ripple3 = document.getElementById("dot-logo-ripple-3");
    if (ripple1 && ripple2 && ripple3) {
      ripple1.classList.add("ripple-1-animate");
      ripple2.classList.add("ripple-2-animate");
      ripple3.classList.add("ripple-3-animate");
    }
  }

  const dotLogoUnhovered = () => {
    const ripple1 = document.getElementById("dot-logo-ripple-1");
    const ripple2 = document.getElementById("dot-logo-ripple-2");
    const ripple3 = document.getElementById("dot-logo-ripple-3");
    if (ripple1 && ripple2 && ripple3) {
      ripple1.classList.remove("ripple-1-animate");
      ripple2.classList.remove("ripple-2-animate");
      ripple3.classList.remove("ripple-3-animate");
    }
  }

  const changeView = (e: any, newView: View) => {
    e.preventDefault();
    if (newView !== view) {
      if (newView === View.ABOUT) {
        window.history.pushState({view: newView, section: AboutSection.ABOUT}, "", '/about/About');
      } else {
        window.history.pushState({view: newView, audioState: audioState}, "", `/${newView}`)
      }
      setView(newView);
    }
  }

  const turnOnLightMode = () => {
    setSettingsState({
      ...settingsState,
      backgroundSettings: {
        dotCount: settingsState.backgroundSettings.dotCount,
        dotActivity: settingsState.backgroundSettings.dotActivity,
        colorPalette: ColorPalette.DEFAULT
      }
    });
    logEvent(analytics, "light_mode_pressed");
  }

  const turnOnDarkMode = () => {
    setSettingsState({
      ...settingsState,
      backgroundSettings: {
        dotCount: settingsState.backgroundSettings.dotCount,
        dotActivity: settingsState.backgroundSettings.dotActivity,
        colorPalette: ColorPalette.DARK_MODE
      }
    });
    logEvent(analytics, "dark_mode_pressed");
  }

  return (
      <div className="header">
        <div id="dot-logo-ripple-3" className="dot-logo ripple-3"></div>
        <div id="dot-logo-ripple-2" className="dot-logo ripple-2"></div>
        <div id="dot-logo-ripple-1" className="dot-logo ripple-1"></div>
        <div onMouseEnter={() => dotLogoHovered()} onMouseLeave={() => dotLogoUnhovered()} className="dot-logo"></div>
        <div className="header-text-content">
          <h1 className="title-text">
            Dot Focus
          </h1>
          <nav className="menu-text-container">
            <a href="/home" onClick={(e) => changeView(e, View.MAIN)} className="menu-text">Home</a>
            <a href="/about" onClick={(e) => changeView(e, View.ABOUT)} className="menu-text">About</a>
            <a href="/contact" onClick={(e) => changeView(e, View.CONTACT)} className="menu-text">Contact</a>
            <a href="/settings" onClick={(e) => changeView(e, View.SETTINGS)} className="menu-text">Settings</a>
            <svg onClick={() => openSoundsDialog()} xmlns="http://www.w3.org/2000/svg" className="audio-icon" width="24" height="24" viewBox="0 0 24 24"><path d="M3 18v-6a9 9 0 0 1 18 0v6"></path><path d="M21 19a2 2 0 0 1-2 2h-1a2 2 0 0 1-2-2v-3a2 2 0 0 1 2-2h3zM3 19a2 2 0 0 0 2 2h1a2 2 0 0 0 2-2v-3a2 2 0 0 0-2-2H3z"></path></svg>
            <svg onClick={() => openMusicDialog()} xmlns="http://www.w3.org/2000/svg" className="audio-icon" width="24" height="24" viewBox="0 0 24 24"><path d="M9 18V5l12-2v13"></path><circle cx="6" cy="18" r="3"></circle><circle cx="18" cy="16" r="3"></circle></svg>
            {settingsState.backgroundSettings.colorPalette === ColorPalette.DARK_MODE
              ? <svg onClick={() => turnOnLightMode()} className="light-mode-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><circle cx="12" cy="12" r="5"></circle><line x1="12" y1="1" x2="12" y2="3"></line><line x1="12" y1="21" x2="12" y2="23"></line><line x1="4.22" y1="4.22" x2="5.64" y2="5.64"></line><line x1="18.36" y1="18.36" x2="19.78" y2="19.78"></line><line x1="1" y1="12" x2="3" y2="12"></line><line x1="21" y1="12" x2="23" y2="12"></line><line x1="4.22" y1="19.78" x2="5.64" y2="18.36"></line><line x1="18.36" y1="5.64" x2="19.78" y2="4.22"></line></svg>
              : <svg onClick={() => turnOnDarkMode()} className="dark-mode-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z"></path></svg>          
            }
          </nav>
        </div>
      </div>
  );
}