import { useContext, useEffect} from "react";
import { ColorPalette, DotDataContext } from "../../contexts/DotDataContext/DotDataContext";
import { AudioDialogColorPalette } from "../DotAudioSelectDialog/DotAudioSelectDialog";
import './DotLoadingComponent.scss';

export interface LoaderProps {
    colorScheme: ColorPalette;
    message: string;
}

export const DotLoadingComponent = (props: LoaderProps) => {
    const {loadingState} = useContext(DotDataContext);

    useEffect(() => {
        const getColorPalette = (): AudioDialogColorPalette => {
            switch (props.colorScheme) {
                case ColorPalette.DARK_MODE:
                  return {
                    background: 'hsl(0, 0%, 20%)',
                    subtitleColor: 'hsl(40, 100%, 98%)'
                  }
                default:
                  return {
                    background: 'hsl(210, 100%, 60%)',
                    subtitleColor: 'hsl(208, 100%, 89%)',
                  }
              }
        }

        const maskElem = document.getElementById('loader-mask');
        if (maskElem) {
            maskElem.style.background = getColorPalette().background;
        }

    }, [props.colorScheme, loadingState.isLoading])

    const renderLoader = () => {
        if (!loadingState.isLoading) {
            return null;
        } 

        return (
            <div className="loader-mask">
                <div className="lds-facebook"><div></div><div></div><div></div></div>
                <div className="loader-text text">{props.message}</div>
            </div>
        )
    }

    return renderLoader();
}