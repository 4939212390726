import { createContext, useEffect, useState } from "react";
import { idb } from "../../App";
import { ToDoItem } from "../../components/ToDoList/ToDoList";

export enum ColorPalette {
    DEFAULT = 'default',
    DARK_MODE = 'dark mode'
}

export enum StorageOption {
    KEEP = "keep",
    CLEAR = "clear"
}

export enum PomodoroStatus {
    WORKING = "working",
    SMALL_BREAK = "small break",
    LARGE_BREAK = "large break"
}

export type PomodoroState = {
    timerOn: boolean;
    secondsRemaining: number;
    currentCycle: number;
    pomodoroStatus: PomodoroStatus;
    totalCycles: number;
    longBreakLength: number;
    shortBreakLength: number;
    pomodoroTimerLength: number;
    autoBeginBreaks: boolean;
    autoBeginTimer: boolean;
    pauseSoundsOnBreak: boolean;
}

export const WORKING_TIMER_SECONDS = parseInt(localStorage.getItem('WORKING_TIMER_SECONDS') || '1500');
export const SMALL_BREAK_TIMER_SECONDS = parseInt(localStorage.getItem('SMALL_BREAK_TIMER_SECONDS') || '300');
export const LARGE_BREAK_TIMER_SECONDS = parseInt(localStorage.getItem('LARGE_BREAK_TIMER_SECONDS') || '1200');
export const CYCLES = parseInt(localStorage.getItem('CYCLES') || '4');
export const AUTO_BEGIN_BREAK = (localStorage.getItem('AUTO_BEGIN_BREAKS') || 'false') === 'true';
export const AUTO_BEGIN_TIMER = (localStorage.getItem('AUTO_BEGIN_TIMER') || 'false') === 'true';
export const PAUSE_SOUNDS_ON_BREAK = (localStorage.getItem('PAUSE_SOUNDS_ON_BREAK') || 'false') === 'true';

export interface LoadingState {
    loadingMessage: string;
    isLoading: boolean;
}

export type DotSettings = {
    storageOption: StorageOption;
    backgroundSettings: {
        dotCount: number,
        dotActivity: number,
        colorPalette: ColorPalette
    },
    displaySettings: {
        showNotes: boolean,
        showToDoList: boolean,
        showPomodoro: boolean
    },
}

const defaultContextValue = {
    settingsState: {
        storageOption: StorageOption.KEEP,
        backgroundSettings: {
            dotCount: 10,
            dotActivity: 1,
            colorPalette: ColorPalette.DEFAULT
        },
        displaySettings: {
            showNotes: false,
            showToDoList: false,
            showPomodoro: false
        }
    },
    setSettingsState: (settings: DotSettings) => {},
    notes: '',
    setNotes: (notes: string) => {},
    toDoItems: [] as ToDoItem[],
    setToDoItems: (toDoItems: ToDoItem[]) => {},
    toDoItemRefs: [] as React.RefObject<HTMLInputElement>[],
    setToDoItemRefs: (toDoItemRefs: React.RefObject<HTMLInputElement>[]) => {},
    pomodoroState: {
        timerOn: false,
        secondsRemaining: WORKING_TIMER_SECONDS,
        currentCycle: 0,
        pomodoroStatus: PomodoroStatus.WORKING,
        longBreakLength: LARGE_BREAK_TIMER_SECONDS,
        shortBreakLength: SMALL_BREAK_TIMER_SECONDS,
        pomodoroTimerLength: WORKING_TIMER_SECONDS,
        totalCycles: CYCLES,
        autoBeginBreaks: AUTO_BEGIN_BREAK,
        autoBeginTimer: AUTO_BEGIN_TIMER,
        pauseSoundsOnBreak: PAUSE_SOUNDS_ON_BREAK,
    },
    setPomodoroState: (pomodoroState: PomodoroState) => {}, 
    loadingState: {
        loadingMessage: "",
        isLoading: false
    },
    setLoadingState: (loadingState: LoadingState) => {}
}

export const DotDataContext = createContext(defaultContextValue);

export const DotDataProvider = (props: any) => {
    const [settingsState, setSettingsState] = useState<DotSettings>(defaultContextValue.settingsState);
    const [notes, setNotes] = useState('');
    const [toDoItems, setToDoItems] = useState<ToDoItem[]>([]);
    const [toDoItemRefs, setToDoItemRefs] = useState<React.RefObject<HTMLInputElement>[]>([]);
    const [pomodoroState, setPomodoroState] = useState(defaultContextValue.pomodoroState);
    const [loadingState, setLoadingState] = useState<LoadingState>(defaultContextValue.loadingState);

    useEffect(() => {
        const loadSettingsFromIdb = async () => {
            const allSettings = (await idb.db()).getAll('settings');
            allSettings.then(response => {
                if (response && response[0]) {
                    const settings = response[0] as DotSettings;
                    setSettingsState(settings);
                } else {
                    setSettingsState({
                        ...settingsState,
                        displaySettings: {
                            showNotes: true,
                            showToDoList: true,
                            showPomodoro: true,
                        }
                    })
                }
            })
        }

        loadSettingsFromIdb();
    }, []);

    useEffect(() => {
        const addSettingsToIdb = async () => {
            (await idb.db()).put('settings', settingsState, 'settings');
        }

        const removeItemsFromStorage = async () => {
            window.localStorage.removeItem('notes');
            (await idb.db()).clear('to-do-items');
        }

        if (settingsState.storageOption === StorageOption.CLEAR) {
            removeItemsFromStorage();
        }

        addSettingsToIdb();
    }, [settingsState]);

    useEffect(() => {
    }, [notes, toDoItems]);
    
    useEffect(() => {
    }, [pomodoroState]);

    return (
        <DotDataContext.Provider value={{
            settingsState, setSettingsState, 
            notes, setNotes,
            toDoItems, setToDoItems,
            toDoItemRefs, setToDoItemRefs,
            pomodoroState, setPomodoroState,
            loadingState, setLoadingState
        }}>
            {props.children}
        </DotDataContext.Provider>
    );
}