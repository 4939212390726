import jwt from "jsonwebtoken";

// ***************************************************
//                        TODO: 
//                FIGURE OUT HOW TO AVOID 
//                INCLUDING PRIVATE KEY IN JS
// ***************************************************
const privateKey = `-----BEGIN PRIVATE KEY-----
MIGTAgEAMBMGByqGSM49AgEGCCqGSM49AwEHBHkwdwIBAQQgqfggpZxcaanVvEGXlfQ0riCjSbz9WZ27vj1RcB3aEPqgCgYIKoZIzj0DAQehRANCAAQfQ6odV0j2NmOc6EMRXBYJiWwldh54dUAW0HdV7KOToYzvvX1O3MB//OkqYVl6w8vtH32WKhTReEl8T8xvMl6I
-----END PRIVATE KEY-----`

const header = {
    alg: "ES256",
    kid: "XT7GY7JP78"
}

const payload = {
    iss: "52X3QC6B4W",
    iat: Date.now(),
    exp: 86400,
    origin: [
       "focuswithdot.com",
       "www.focuswithdot.com",
       "https://focuswithdot.com",
       // TODO: REMOVE THIS
       "localhost:3000" 
     ]
}

export const generateJwtToken = (): string => {
    const token = jwt.sign({}, privateKey, {
        algorithm: "ES256",
        expiresIn: "180d",
        issuer: payload.iss,
        header: {
            alg: header.alg,
            kid: header.kid
        }
    });

    return token;
}