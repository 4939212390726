import { useDotAudioContext } from '../../contexts/DotAudioContext/DotAudioContextCustomHook';

import '../../App.scss';
import './DotAudioPlayer.scss';
import { useEffect } from 'react';

export const DotAudioPlayerComponent = () => {
    const {
        audioState, 
        appleMusicState,
        playAudio,
        pauseAudio,
        setVolume,
        setPrevious, 
        setNext,
        shuffle,
        appleMusicActive
    } = useDotAudioContext();

    useEffect(() => {
    }, [audioState, appleMusicState])

    const volumeChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        const volumeSlider = e.target;

        if (appleMusicActive()) {
            appleMusicState.musicKit!.player.volume = parseInt(volumeSlider.value) / 100;
            setVolume(parseInt(volumeSlider.value));
        } else {
            setVolume(parseInt(volumeSlider.value));
        }
    }

    const renderPlayOrPauseIcon = () => {
        if (appleMusicActive()) {
            return appleMusicState.appleMusicPlaying
                ? <svg className="music-player-icon" onClick={() => pauseAudio()} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="hsl(40, 100%, 98%)" stroke="hsl(40, 100%, 98%)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><rect x="6" y="4" width="4" height="16"></rect><rect x="14" y="4" width="4" height="16"></rect></svg>    
                : <svg className="music-player-icon" onClick={() => playAudio()} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="hsl(40, 100%, 98%)" stroke="hsl(40, 100%, 98%)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><polygon points="5 3 19 12 5 21 5 3"></polygon></svg>
        } else {
            return audioState.audioPlaying
                ? <svg className="music-player-icon" onClick={() => pauseAudio()} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="hsl(40, 100%, 98%)" stroke="hsl(40, 100%, 98%)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><rect x="6" y="4" width="4" height="16"></rect><rect x="14" y="4" width="4" height="16"></rect></svg>    
                : <svg className="music-player-icon" onClick={() => playAudio()} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="hsl(40, 100%, 98%)" stroke="hsl(40, 100%, 98%)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><polygon points="5 3 19 12 5 21 5 3"></polygon></svg>
        }
    }

    return(
        <div className="music-player-container">
            <div className="music-player-icon-row">
                {renderPlayOrPauseIcon()}
                <svg className="music-player-icon" onClick={() => shuffle()} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="hsl(40, 100%, 98%)" stroke="hsl(40, 100%, 98%)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><polyline points="16 3 21 3 21 8"></polyline><line x1="4" y1="20" x2="21" y2="3"></line><polyline points="21 16 21 21 16 21"></polyline><line x1="15" y1="15" x2="21" y2="21"></line><line x1="4" y1="4" x2="9" y2="9"></line></svg>
                <svg className="music-player-icon" onClick={() => setPrevious()} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="hsl(40, 100%, 98%)" stroke="hsl(40, 100%, 98%)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><polygon points="19 20 9 12 19 4 19 20"></polygon><line x1="5" y1="19" x2="5" y2="5"></line></svg>
                <svg className="music-player-icon" onClick={() => setNext()} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="hsl(40, 100%, 98%)" stroke="hsl(40, 100%, 98%)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><polygon points="5 4 15 12 5 20 5 4"></polygon><line x1="19" y1="5" x2="19" y2="19"></line></svg>
            </div>
            <div className="volume-slider-container">
                <input aria-label="volume slider" name="volume-slider" onChange={(e) => {volumeChanged(e)}} type="range" defaultValue={audioState.currVolume} min="0" max="100" className="volume-slider" id="volume-slider"></input>
            </div>
        </div>
    );
}
