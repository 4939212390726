import { useContext, useEffect, useState } from "react";
import { DotAudioContext } from "../../contexts/DotAudioContext/DotAudioContext";
import { useDotAudioContext } from "../../contexts/DotAudioContext/DotAudioContextCustomHook";
import { ColorPalette, DotDataContext } from "../../contexts/DotDataContext/DotDataContext";
import { DotNavigationContext, View } from "../../contexts/NavigationContext/NavigationContext";
import { AppleMusicHelper, AppleMusicResource, AppleMusicTracks } from "../../utils/AppleMusicHelper";
import { AudioDialogColorPalette } from "../DotAudioSelectDialog/DotAudioSelectDialog";
import { DotSoundSelectProps } from "../DotAudioSelectDialog/DotSoundSelectDialog";

import "./DotAppleMusicPlaylistDialog.scss";

export const DotAppleMusicPlaylistDialogComponent = (props: {soundSelectProps: DotSoundSelectProps}) => {
    const {settingsState, setLoadingState} = useContext(DotDataContext);
    const {appleMusicState, audioState, setAppleMusicState, setAudioState} = useContext(DotAudioContext);
    const {stopAppleMusic, logoutAppleMusic} = useDotAudioContext();
    const {view, setView} = useContext(DotNavigationContext);
    // TODO: use state for a loading spinner, when playlists haven't been fetched yet, show loader

    // TODO: these playlists should be in context to be stored in a central location
    const [appleMusicPlaylists, setAppleMusicPlaylists] = useState<AppleMusicResource[]>();
    const [selectedPlaylist, setSelectedPlaylist] = useState<AppleMusicResource>();

    useEffect(() => {
        if (appleMusicState.selectedPlaylist) {
            setSelectedPlaylist(appleMusicState.selectedPlaylist);
        }
    }, []);

    useEffect(() => {

    }, [audioState, view])

    useEffect(() => {}, [selectedPlaylist, appleMusicState]);

    useEffect(() => {
        if (props.soundSelectProps.dialogOpened && !appleMusicPlaylists && appleMusicState.musicKit) {
                AppleMusicHelper.getPlaylists(appleMusicState.musicKit).then((playlists) => {
                    setAppleMusicPlaylists([...playlists]);
                });
        }
    }, [props.soundSelectProps.dialogOpened, appleMusicPlaylists, appleMusicState.musicKit])

    useEffect(() => {
        const getColorPalette = (): AudioDialogColorPalette => {
            switch (settingsState.backgroundSettings.colorPalette) {
                case ColorPalette.DARK_MODE:
                  return {
                    background: 'hsl(0, 0%, 20%)',
                    subtitleColor: 'hsl(40, 100%, 98%)'
                  }
                default:
                  return {
                    background: 'hsl(210, 100%, 60%)',
                    subtitleColor: 'hsl(208, 100%, 89%)',
                  }
              }
        }

        const maskElem = document.getElementById('apple-music-dialog-mask');
        if (maskElem) {
            maskElem.style.background = getColorPalette().background;
        }

        const subtitleTextElems = document.getElementsByClassName("music-card-text-subtitle") as HTMLCollectionOf<HTMLElement>;
        for (let i = 0; i < subtitleTextElems.length; i++) {
            const subtitleElem = subtitleTextElems[i];
            if (subtitleElem) {
                subtitleElem.style.color = getColorPalette().subtitleColor;
            }
        }
    }, [settingsState.backgroundSettings.colorPalette, props.soundSelectProps.dialogOpened])

    const closeDialog = () => {
        setSelectedPlaylist(undefined);
        props.soundSelectProps.dialogClosedCallback();
    }

    const selectPlaylist = (playlist: AppleMusicResource) => {
        setSelectedPlaylist(playlist);
    }

    const saveAppleMusicPlaylist = async () => {
        if (view !== View.MAIN) {
            window.history.pushState({view: View.MAIN, audioState: audioState}, "", `/${View.MAIN}`);
            setView(View.MAIN);
        }

        // Logic to start apple music playlist
        if (selectedPlaylist && appleMusicState.musicKit && selectedPlaylist !== appleMusicState.selectedPlaylist) {
            setLoadingState({loadingMessage: "getting all playlist songs...", isLoading: true});
            // TODO: Put the duplicate logic here (and in the shuffle method) in a common function
            const songs = await AppleMusicHelper.getAllPlaylistSongs(appleMusicState.musicKit, selectedPlaylist.id);

            const songIds = songs.flatMap(song => song.id);

            // Prepare queue
            if (appleMusicState.musicKit.player.queue.length > 0) {
                await appleMusicState.musicKit.setQueue({});
            }

            // Batch songs to allow a queue > 100
            setLoadingState({loadingMessage: "adding playlist songs to your queue...", isLoading: true});
            const playLaterPromises: Promise<void>[] = [];
            while (songIds.length > 0) {
                const songBatch = songIds.splice(0, 100);
                playLaterPromises.push(appleMusicState.musicKit.playLater({songs: songBatch}));
            }

            await Promise.all(playLaterPromises);

            setLoadingState({loadingMessage: "", isLoading: false});

            appleMusicState.musicKit.player.shuffleMode = 1;
            appleMusicState.musicKit.player.repeatMode = 2;

            // Pause Apple Music and / or current station
            if (appleMusicState.appleMusicPlaying) {
                appleMusicState.musicKit.player.stop();
            } 
            
            if (audioState.audioPlaying) {
                setAudioState({...audioState, audioPlaying: false});
                // Pause currently playing audio
                const audio = document.getElementById("audio") as HTMLAudioElement;
                audio.pause();
            }

            // Send playlist to context
            setAppleMusicState({...appleMusicState, selectedPlaylist: selectedPlaylist, appleMusicPlaying: false});
        }

        closeDialog();
    }

    const appleMusicLogout = () => {
        logoutAppleMusic();

        closeDialog();
    }

    const quitAppleMusic = () => {
        stopAppleMusic();
        closeDialog();
    }

    const renderPlaylists = () => {
        if (appleMusicPlaylists && appleMusicPlaylists.length > 0) {
            return appleMusicPlaylists.map(playlist => {
                /*
                 confusing class names, but unselectable refers to selecting highlighted text
                 while selected refers to the class used to actually show the selected
                 playlist (currently playing)
                */
                const playlistSelected = selectedPlaylist
                    ? selectedPlaylist
                    : appleMusicState.selectedPlaylist;

                const playlistNameCssClass = playlistSelected === playlist 
                    ? "apple-music-playlist-name text playlist-text unselectable selected"
                    : "apple-music-playlist-name text playlist-text unselectable";
                return (
                    <div key={playlist.id} className="apple-music-single-playlist-container">
                        <p onClick={() => selectPlaylist(playlist)} className={playlistNameCssClass}>
                            {playlist.attributes.name}
                        </p>
                    </div>
                )
            })
        }
    }

    const renderHeader = () => {
        if (appleMusicPlaylists && appleMusicPlaylists.length > 0) {
            return (
                <>
                <svg onClick={() => closeDialog()} xmlns="http://www.w3.org/2000/svg" className="apple-music-dialog-exit-icon" viewBox="0 0 24 24" fill="none" stroke="hsl(40, 100%, 98%)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                <span className="dialog-header-text text">{
                    appleMusicState.selectedPlaylist
                        ? `now playing: ${appleMusicState.selectedPlaylist.attributes.name}`
                        : 'scroll and select a playlist'
                }</span>
                </>
            );
        } else {
            return (
                <span className="dialog-header-text text">
                    Loading your Apple Music playlists...
                </span>
            )
        }
    }

    const renderFooterButtons = () => {
        const button1 =  
            <div onClick={() => quitAppleMusic()} className="apple-music-dialog-button cancel">
                <p className="button-text">Quit A.M.</p>
            </div>

        const button2 =                             
            <div onClick={() => saveAppleMusicPlaylist()} className="apple-music-dialog-button">
                <p className="button-text">Save</p>
            </div>

        const button3 = 
            <div onClick={() => appleMusicLogout()} className="apple-music-dialog-button logout">
                <p className="button-text">Log Out</p>
            </div>
        const buttons = (appleMusicState.selectedPlaylist && !selectedPlaylist) || (appleMusicState.selectedPlaylist === selectedPlaylist && selectedPlaylist)
            ? <>{button1}{button3}</>
            : <>{button2}{button1}{button3}</>

        return (
            <>
                {buttons}
            </>
        )
    }

    if (props.soundSelectProps.dialogOpened) {
        return (
            <>
                <div id="apple-music-dialog-mask" className="apple-music-dialog-mask"></div>
                <div>
                    <div className="apple-music-dialog-container">
                        <div className="apple-music-dialog-header">
                            {renderHeader()}
                        </div>
                        <div className="apple-music-dialog-content">
                            {renderPlaylists()}
                        </div>
                        <div className="apple-music-dialog-footer">
                            {renderFooterButtons()}
                        </div>
                    </div>
                </div>
            </>
        )
    } else {
        return null;
    }
}