import React, { ChangeEvent, useContext, useEffect, useState } from 'react';

import '../../App.scss';
import { ColorPalette, DotDataContext, StorageOption } from '../../contexts/DotDataContext/DotDataContext';
import { ClearItemState } from '../ToDoList/ToDoList';
import './Notes.scss';

const localStorage = window.localStorage;
const DELAY_UPDATE_MS = 300;

type NotesColorPalette = {
    notesBackground: string;
    textColor: string;
    border: string;
}

export const NotesComponent = () => {
    const [clearNotesState, setClearNotesState] = useState<ClearItemState>(ClearItemState.CLEAR_ITEM_NOT_VISIBLE);
    const textAreaRef = React.createRef<HTMLTextAreaElement>();

    const {settingsState, notes, setNotes} = useContext(DotDataContext);

    useEffect(() => {
        /* 
            IMPORTANT: Why this line? Well -- we want to persist 
            the information if the setting is set to "keep". 
            So, the first thing we do is grab the item if it exists
            in local storage (or IDB). 

            If it doesn't, it means we've got a setting to clear storage
            - so, there are two cases

            1 - this is the first load for the session, then the session state
            and the context state will be empty. 

            2 - this is the second or more render for the session, so the local storage will be 
            empty. However, the context state should still have state, until refresh, or exit.
            So, we grab that.
        */
        const storedNotes = localStorage.getItem('notes') || notes;
        setNotes(storedNotes);
    }, []);

    useEffect(() => {
        const getColorPalette = (): NotesColorPalette => {
            switch(settingsState.backgroundSettings.colorPalette) {
                case ColorPalette.DARK_MODE:
                    return {
                        notesBackground: 'hsl(0, 0%, 20%)',
                        textColor: 'hsl(207, 100%, 95%)',
                        border: '0.4pt solid grey'
                    }
                default: 
                    return {
                        notesBackground: 'hsl(207, 100%, 95%)',
                        textColor: 'hsl(0, 0%, 0%)',
                        border: 'none'
                    }
            }
        }

        const colorPalette = getColorPalette();

        const notesContainerElem = document.getElementById('notes-container');
        if (notesContainerElem) {
            notesContainerElem.style.border = colorPalette.border;
            notesContainerElem.style.background = colorPalette.notesBackground;
            const notesTextElems = notesContainerElem.getElementsByClassName("text") as HTMLCollectionOf<HTMLElement>;
            for (let i = 0; i < notesTextElems.length; i++) {
                const elem = notesTextElems[i];
                elem.style.color = colorPalette.textColor;
            }
        }
    }, [settingsState])

    useEffect(() => {
        if (settingsState.storageOption === StorageOption.KEEP) {
            const delayLocalStorageUpdate = setTimeout(() => {
                const text = notes || '';
                localStorage.setItem('notes', text);
            }, DELAY_UPDATE_MS);
    
            return () => clearTimeout(delayLocalStorageUpdate);
        }
    }, [notes, settingsState.storageOption])

    useEffect(() => {
        const clearNotesTimeout = setTimeout(() => {
            if (clearNotesState === ClearItemState.CLEAR_ITEM_CLICKED) {
              setClearNotesState(ClearItemState.CLEAR_ITEM_VISIBLE);
            }
          }, 5000);
    
          return (() => {
            clearTimeout(clearNotesTimeout);
          })
    }, [clearNotesState]);

    const getNotesHeaderText = () => {
        if (!notes && notes.length === 0) {
            return "";
        }
        switch (clearNotesState) {
            case ClearItemState.CLEAR_ITEM_NOT_VISIBLE:
            case ClearItemState.CLEAR_ITEM_VISIBLE:
                return "Clear Items";
            case ClearItemState.CLEAR_ITEM_CLICKED:
                return "Confirm Clear";
        }
    }

    const notesTextChanged = (e: ChangeEvent) => {
        const notesElement  = e.target as HTMLTextAreaElement;
        const value = notesElement.value;

        setNotes(value);
    }

    const clearNotes = () => {
        setNotes('');
        if (textAreaRef.current) {
            textAreaRef.current.value = '';
        }
        setClearNotesState(ClearItemState.CLEAR_ITEM_NOT_VISIBLE);
    }

    const clearClicked = () => {
    switch (clearNotesState) {
        case ClearItemState.CLEAR_ITEM_NOT_VISIBLE:
        case ClearItemState.CLEAR_ITEM_VISIBLE:
            setClearNotesState(ClearItemState.CLEAR_ITEM_CLICKED);
            break;
        case ClearItemState.CLEAR_ITEM_CLICKED:
            clearNotes();
            break;
        }    
    }

    return settingsState.displaySettings.showNotes ?
        <aside id="notes-container" className="notes-container">
            <div className="notes-container-inner">
                <div className="notes-header-container">
                    <span className="text notes-header-left">Notes</span>
                     <span onClick={() => clearClicked()} className="text notes-header-right">{getNotesHeaderText()}</span>
                </div>
                <textarea aria-label="notes text area" id="notes-textarea" ref={textAreaRef} defaultValue={notes || ''} onChange={(e) => notesTextChanged(e)} className="text notes-text"></textarea>
            </div>
        </aside>
    : null
}