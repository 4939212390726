import { logEvent } from "firebase/analytics";
import { useEffect } from "react"
import { analytics } from "../../App";
import { getSoundURL } from "../../contexts/DotAudioContext/DotAudioContext";
import { useDotAudioContext } from "../../contexts/DotAudioContext/DotAudioContextCustomHook";

import './DotTrackSelect.scss';

export const SOUNDS_TIME_LISTENED_STAT_KEY = 'sounds-time-listened';

export type DotTrackCategories = "Nature" | "Ambient" | "Meditative" | "";

export interface DotTrackSelectProps {
    trackCategory: DotTrackCategories;
}

export const natureTracks: Record<string, string[]> = {
    Water: [
        'Gentle Waves on a Rocky Beach', 
        'Slowly Moving Stream', 
        'Soft Rain on a Chilly Night Outside' 
    ],
    Birds: [
        'Morning Birdsongs in Cornwall', 
        'Seagulls by the Beach', 
        'Voices of African Birds', 
        'Crisp Morning Outside with Birdsong' 
    ],
    Others: [
        'Windy Forest (No Birds)', 
        'Gentle Breeze Between Wheat', 
        'Frogs, Bugs at a Mountain Home',
        'Rumbling Deep Thunder (No Rain)',
        'Cicadas in the Summer Heat'
    ]
}

export const ambientTracks: Record<string, string[]> = {
    "Out And About": [
        "Airport Cafe", 
        "Bustling City Dive Bar", 
        "A Walk Through the City at Night" 
    ],
    Cozy: [
        "Night Time City Sounds from Inside", 
        "Crackling Fireplace in the Winter", 
        "Dim Bedroom Ceiling Fan",
        "Scratchy Vintage Vinyl Record",
        "Book Pages Calmly Turning"
    ],
    Transport: [
        "First Class - Airplane Ambiance",
        "Spaceship Travel to Alpha Centauri"
    ]
}

export const meditativeTracks: Record<string, string[]> = {
    Meditative: [
        "Humming, Singing Bowls",
        "Gentle Wind Chimes",
        "Deep Focus Meditation Music",
    ]
}

export const DotTrackSelectComponent = (props: {trackSelectProps: DotTrackSelectProps}) => {
    const {
        audioState, 
        setAudioState, 
        soundsListenedInterval, 
        setSoundsListenedInterval,
        soundsListenedIntervals,
        setSoundsListenedIntervals
    } = useDotAudioContext();

    useEffect(() => {
        audioState.selectedSoundTracks.forEach(trackId => {
            addSelectedEffect(trackId);
        });
    });

    useEffect(() => {
    }, [soundsListenedIntervals]);

    const addSelectedEffect = (trackId: string) => {
        const trackElem = document.getElementById(trackId);
        if (trackElem) {
            trackElem.classList.add("track-col-selected");
        }
    }

    const removeSelectedEffect = (trackId: string) => {
        const trackElem = document.getElementById(trackId);
        if (trackElem) {
            trackElem.classList.remove("track-col-selected");
        }
    }

    const trackClicked = (clickedTrackId: string) => {
        const currSelectedTracks = audioState.selectedSoundTracks.filter((trackId) => 
            trackId !== clickedTrackId
        );

        if (currSelectedTracks.length === audioState.selectedSoundTracks.length) {
            if (currSelectedTracks.length === 0 + 1 && !soundsListenedInterval) {
                const soundTimeInterval = setInterval(() => {
                    const soundsTimeListened = localStorage.getItem(SOUNDS_TIME_LISTENED_STAT_KEY);
                    const newSoundsTimeListened = soundsTimeListened ? parseInt(soundsTimeListened) + 1: 1;
                    localStorage.setItem(SOUNDS_TIME_LISTENED_STAT_KEY, newSoundsTimeListened.toString());
                }, 60000)
                setSoundsListenedInterval(soundTimeInterval);
            }

            const soundsTimeIntervals = setInterval(() => {
                const soundTimeListened = localStorage.getItem(`${clickedTrackId}-${SOUNDS_TIME_LISTENED_STAT_KEY}`);
                const newSoundTimeListened = soundTimeListened ? parseInt(soundTimeListened) + 1 : 1;
                localStorage.setItem(`${clickedTrackId}-${SOUNDS_TIME_LISTENED_STAT_KEY}`, newSoundTimeListened.toString());
            }, 60000);
            const timeIntervals = soundsListenedIntervals;
            timeIntervals.push({trackId: clickedTrackId, interval: soundsTimeIntervals})
            setSoundsListenedIntervals([...timeIntervals]);

            // TODO: remove the + 1, currently the track list has an extra blank string in it...shitty
            if (audioState.selectedSoundTracks.length < 3 + 1) {
                currSelectedTracks.push(clickedTrackId);

                const audio = document.createElement('audio') as HTMLAudioElement;
                audio.src = getSoundURL(clickedTrackId);
                audio.id = `${clickedTrackId}-audio`;
                audio.volume = 30 / 100;
                localStorage.setItem(`${clickedTrackId}-volume`, '30');
                audio.loop = true;
                audio.play();

                document.body.appendChild(audio);
                logEvent(analytics, "sound_selected", {sound: clickedTrackId});
            } else {
                return;
            }
        } else {
            removeSelectedEffect(clickedTrackId);
            const audio = document.getElementById(`${clickedTrackId}-audio`) as HTMLAudioElement;
            audio.pause();
            audio.remove();
            localStorage.removeItem(`${clickedTrackId}-volume`);
            logEvent(analytics, "sound_unselected", {sound: clickedTrackId});

            // TODO: remove the + 1, currently the track list has an extra blank string in it...shitty
            if (audioState.selectedSoundTracks.length === 1 + 1 && soundsListenedInterval) {
                clearInterval(soundsListenedInterval);
                setSoundsListenedInterval(undefined);
            }

            const intervalIdx = soundsListenedIntervals.findIndex((track) => track.trackId === clickedTrackId);
            if (soundsListenedIntervals[intervalIdx]) {
                clearInterval(soundsListenedIntervals[intervalIdx].interval);
            }
            const intervals = soundsListenedIntervals;
            intervals.splice(intervalIdx, 1);
            setSoundsListenedIntervals([...intervals]);
        }

        setAudioState({
            ...audioState,
            selectedSoundTracks: [...currSelectedTracks]
        });
    }

    const volumeChanged = (e: React.ChangeEvent<HTMLInputElement>, trackId: string) => {
        const trackAudioElem = document.getElementById(`${trackId}-audio`) as HTMLAudioElement;
        if (trackAudioElem) {
            trackAudioElem.volume = parseInt(e.target.value) / 100;
            localStorage.setItem(`${trackId}-volume`, e.target.value);
        }
    }

    const getTracksFromCategory = (category: DotTrackCategories) => {
        switch (category) {
            case "Nature":
                return natureTracks;
            case "Ambient":
                return ambientTracks;
            case "Meditative":
                return meditativeTracks;
            case "":
                return natureTracks;
        }
    }

    const renderTracks = () => {
        const trackCategory = props.trackSelectProps.trackCategory;
        const tracks = getTracksFromCategory(trackCategory);

        return Object.entries(tracks).map((trackSection) => {
            const sectionTitle = trackSection[0];
            const sectionTracks = trackSection[1];

            const sectionTitleSpacesRmvd = sectionTitle.replaceAll(' ', '');

            return (
                <div key={sectionTitle}>
                    <div key={sectionTitleSpacesRmvd} className="text section-title unselectable">{sectionTitle}</div>
                    {sectionTracks.map((track, idx) => {
                        // replaceAll line is for "Out And About" track key
                        const trackId = `${trackCategory}/${sectionTitleSpacesRmvd}/${sectionTitleSpacesRmvd}-${idx}`;
                        return (
                            <div key={trackId} id={trackId} className="track-col">
                                <div key={`${trackId}-title`} onClick={() => trackClicked(trackId)} className="text track-title unselectable">No. {idx + 1} - {track}</div>
                                {
                                    audioState.selectedSoundTracks.find(track => trackId === track) 
                                    ?
                                        <div className="volume-slider-container">
                                            <input aria-label="volume slider" name="volume-slider" onChange={(e) => {volumeChanged(e, trackId)}} type="range" defaultValue={parseInt(localStorage.getItem(`${trackId}-volume`) || '30')} min="0" max="100" className="volume-slider" id="volume-slider"></input>
                                        </div>
                                    : null
                                }
                            </div>
                        );
                    })}
                </div>
            );
        })
    }

    return (
        <div className="track-select-container">
            {renderTracks()}
        </div>
    );
}