import { logEvent } from "firebase/analytics";
import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { analytics } from "../../App";
import { DotAudioPlayerComponent } from "../../components/DotAudioPlayer/DotAudioPlayer";
import { ambientTracks, meditativeTracks, natureTracks, SOUNDS_TIME_LISTENED_STAT_KEY } from "../../components/DotTrackSelect/DotTrackSelect";
import { HeaderComponent } from "../../components/Header/Header";
import { POMODORO_CYCLES_STAT_KEY } from "../../components/PomodoroTimer/PomodoroTimer";
import { TO_DO_ITEMS_COMPLETED_STAT_KEY } from "../../components/ToDoList/ToDoList";
import { MUSIC_TIME_LISTENED_STAT_KEY } from "../../contexts/DotAudioContext/DotAudioContext";
import { DotNavigationContext, View } from "../../contexts/NavigationContext/NavigationContext";
import "./StatsPage.scss";

enum FavoritesStats {
    MUSIC_CATEGORY,
    SOUND
}

export const StatsPageComponent = (props: any) => {
    const [topMusicCategory, setTopCategory] = useState<{topCategory: string, max: number}>({topCategory: 'Classical', max: 0});
    const [topSound, setTopSound] = useState<{topSound: string, max: number}>({topSound: 'Gentle Waves on a Rocky Beach', max: 0});

    const {setView} = useContext(DotNavigationContext);

    useEffect(() => {}, [topMusicCategory, topSound]);

    useEffect(() => {
        const setFavoriteTimeStat = (type: FavoritesStats) => {
            if (type === FavoritesStats.MUSIC_CATEGORY) {
                const categories = ['Classical', 'Lofi', 'Jazz', 'Soundtrack'];
                let max = 0;
                let maxCategory = 'Classical';
                categories.forEach((category) => {
                    const categoryStat = localStorage.getItem(`${category}-${MUSIC_TIME_LISTENED_STAT_KEY}`) || '0';
                    const statToNum = parseInt(categoryStat);
                    if (statToNum > max) {
                        maxCategory = category;
                        max = statToNum;
                    }
                })
                setTopCategory({topCategory: maxCategory, max: max});
            } else if (type === FavoritesStats.SOUND) {    
                let max = 0;
                let soundId = 'Gentle Waves on a Rocky Beach';
    
                Object.entries(natureTracks).forEach((trackSection) => {
                    const sectionTitle = trackSection[0];
                    const sectionTracks = trackSection[1];
                    const sectionTitleSpacesRmvd = sectionTitle.replaceAll(' ', '');
    
                    sectionTracks.forEach((track, idx) => {
                        const trackId = `Nature/${sectionTitleSpacesRmvd}/${sectionTitleSpacesRmvd}-${idx}`;
                        const categoryStat = localStorage.getItem(`${trackId}-${SOUNDS_TIME_LISTENED_STAT_KEY}`) || '0';
                        const statToNum = parseInt(categoryStat);
                        if (statToNum > max) {
                            soundId = track;
                            max = statToNum;
                        }
                    })
                });
    
                Object.entries(ambientTracks).forEach((trackSection) => {
                    const sectionTitle = trackSection[0];
                    const sectionTracks = trackSection[1];
                    const sectionTitleSpacesRmvd = sectionTitle.replaceAll(' ', '');
    
                    sectionTracks.forEach((track, idx) => {
                        const trackId = `Ambient/${sectionTitleSpacesRmvd}/${sectionTitleSpacesRmvd}-${idx}`;
                        const categoryStat = localStorage.getItem(`${trackId}-${SOUNDS_TIME_LISTENED_STAT_KEY}`) || '0';
                        const statToNum = parseInt(categoryStat);
                        if (statToNum > max) {
                            soundId = track;
                            max = statToNum;
                        }
                    })
                });

                Object.entries(meditativeTracks).forEach((trackSection) => {
                    const sectionTitle = trackSection[0];
                    const sectionTracks = trackSection[1];
                    const sectionTitleSpacesRmvd = sectionTitle.replaceAll(' ', '');
    
                    sectionTracks.forEach((track, idx) => {
                        const trackId = `Meditative/${sectionTitleSpacesRmvd}/${sectionTitleSpacesRmvd}-${idx}`;
                        const categoryStat = localStorage.getItem(`${trackId}-${SOUNDS_TIME_LISTENED_STAT_KEY}`) || '0';
                        const statToNum = parseInt(categoryStat);
                        if (statToNum > max) {
                            soundId = track;
                            max = statToNum;
                        }
                    })
                });
    
                setTopSound({topSound: soundId, max: max});
            }
        }
        setFavoriteTimeStat(FavoritesStats.MUSIC_CATEGORY);
        setFavoriteTimeStat(FavoritesStats.SOUND);

        logEvent(analytics, "stats_page_viewed");
    }, []);

    const getMusicTimeStatTitle = () => {
        const statMin = parseInt(localStorage.getItem(MUSIC_TIME_LISTENED_STAT_KEY) || '0');
        const stat = statMin / 60;
        if (stat >= 0 && stat < 1) {
            return 'Do Re Mi';
        } else if (stat >= 1 && stat < 5) {
            return 'Feeling the Tempo';
        } else if (stat >= 5 && stat < 10) {
            return 'Connoisseur of Melody';
        } else if (stat >= 10 && stat < 20) {
            return 'Melomaniac';
        } else if (stat >= 20) {
            return 'Musical Magnum Opus';
        } else {
            return 'A Statistical Anomaly';
        }
    } 

    const getMusicTopCategoryTitle = () => {
        if (topMusicCategory.topCategory === 'Classical') {
            return 'Musical Time Traveller';
        } else if (topMusicCategory.topCategory === 'Lofi') {
            return 'Arctic Chiller';
        } else if (topMusicCategory.topCategory === 'Soundtrack') {
            return 'Road to Glory';
        } else if (topMusicCategory.topCategory === 'Jazz') {
            return 'Marvelous Modulator';
        } else {
            return 'A Statistical Anomaly';
        }
    }

    const getSoundTimeStatTitle = () => {
        const statMin = parseInt(localStorage.getItem(SOUNDS_TIME_LISTENED_STAT_KEY) || '0');
        const stat = statMin / 60;
        if (stat >= 0 && stat < 1) {
            return 'New Beginnings';
        } else if (stat >= 1 && stat < 5) {
            return 'Wanderlust';
        } else if (stat >= 5 && stat < 10) {
            return 'Traveller';
        } else if (stat >= 10 && stat < 20) {
            return 'Voyager';
        } else if (stat >= 20) {
            return 'Heard it All, and Back for More';
        } else {
            return 'A Statistical Anomaly'
        }
    }

    const getTopSoundStatTitle = () => {
        const sound = topSound.topSound;
        switch (sound) {
            case natureTracks['Water'][0]:
                return '"Let\'s go to the beach, beach!"';
            case natureTracks['Water'][1]:
                return 'Just goin\' with the flow';
            case natureTracks['Water'][2]:
                return 'Passion for Petrichor';
            case natureTracks['Birds'][0]:
                return 'Spending Mornings near Land\'s End';
            case natureTracks['Birds'][1]:
                return 'That\'s a Beach Chicken!';
            case natureTracks['Birds'][2]:
                return 'Serenity in the Savannah';
            case natureTracks['Birds'][3]:
                return 'Early Bird Gets the Worm';
            case natureTracks['Others'][0]:
                return 'Forest Faerie';
            case natureTracks['Others'][1]:
                return 'Going Against the Grain';
            case natureTracks['Others'][2]:
                return 'Summiteer';
            case natureTracks['Others'][3]:
                return 'Thor, the God of Thunder';
            case ambientTracks['Out And About'][0]:
                return 'Catch Me If You Can';
            case ambientTracks['Out And About'][1]:
                return 'Bar Hopper';
            case ambientTracks['Out And About'][2]:
                return 'Urban Explorer';
            case ambientTracks['Cozy'][0]:
                return 'Metropolitan Citizen';
            case ambientTracks['Cozy'][1]:
                return 'Guardian of the Hearth';
            case ambientTracks['Cozy'][2]:
                return 'Hopeful Homebody';
            case ambientTracks['Cozy'][3]:
                return 'The Disc Devastator';
            case ambientTracks['Transport'][0]:
                return '"What\'s the deal with airline food?"';
            case ambientTracks['Transport'][1]:
                return 'The Martian';
            case meditativeTracks['Meditative'][0]:
                return 'The Pro Bowler';
            case meditativeTracks['Meditative'][1]:
                return 'Gone with the Wind';
            case meditativeTracks['Meditative'][2]:
                return 'Zen Master';
            default:
                return 'The Untitled One';
        }
    }
    
    const getCompletedTasksTitle = () => {
        const stat = parseInt(localStorage.getItem(TO_DO_ITEMS_COMPLETED_STAT_KEY) || '0');
        if (stat >= 0 && stat < 25) {
            return 'And so it begins...';
        } else if (stat >= 25 && stat < 100) {
            return 'Getting things done';
        } else if (stat >= 100 && stat < 500) {
            return 'Star Achiever';
        } else if (stat >= 500) {
            return 'Eat. Grind. Sleep. Repeat.';
        } else {
            return 'A Statistical Anomaly';
        }
    }

    const getCompletedPomodoroTitle = () => {
        const stat = parseInt(localStorage.getItem(POMODORO_CYCLES_STAT_KEY) || '0');
        if (stat >= 0 && stat < 4) {
            return '...What\'s a Pomodoro?';
        } else if (stat >= 4 && stat < 40) {
            return 'Trainee of Timers';
        } else if (stat >= 40 && stat < 120) {
            return 'Productivity Speedrunner';
        } else if (stat >= 120) {
            return 'Pomodoro Professional';
        } else {
            return 'A Statistical Anomaly';
        }
    }

    const timeStringFromStatMinutes = (stat: number) => {
        const hours = Math.floor(stat / 60);
        const minutes = stat % 60;

        return `${hours} hours and ${minutes} minutes`;
    }

    const getWorkCompletionStat = (statKey: string) => {
        return parseInt(localStorage.getItem(statKey) || '0');
    }

    const getOverallTimeStat = (statKey: string) => {
        const stat = parseInt(localStorage.getItem(statKey) || '0');
        return timeStringFromStatMinutes(stat);
    }

    const getFavoriteTimeStat = (type: FavoritesStats) => {
        if (type === FavoritesStats.MUSIC_CATEGORY) {
            return `${topMusicCategory.topCategory} at ${timeStringFromStatMinutes(topMusicCategory.max)}`;
        } else if (type === FavoritesStats.SOUND) {
            return `${topSound.topSound}, which you've listened to for ${timeStringFromStatMinutes(topSound.max)}.`;
        }
    }

    const resetStats = () => {
        const confirm = window.confirm('Are you sure you want to clear your stats?');

        if (confirm) {
            const notes = localStorage.getItem('notes') || '';
            localStorage.clear();
            localStorage.setItem('notes', notes);
    
            window.history.back();
            setView(View.MAIN);
        }
    }

    return (
        <div className="stats-container">
            <Helmet>
                <title>time tracking stats | titles and achievements | get motivated!</title>
                <meta
                    name="description"
                    content="Track your time, productivity and usage with Dot Focus stats - see how many tasks you've completed, sounds you've listened to, and more goodness."
                />
                <link rel="canonical" href="https://www.focuswithdot.com/stats"/>
            </Helmet>
            <HeaderComponent
                soundDialogOpenedCallback={props.soundDialogOpenedCallback} 
                musicDialogOpenedCallback={props.musicDialogOpenedCallback}
            />
            <div className="content-container">
                <div className="stats-content-container">
                    <div className="stats-section">
                        <div className="stat-col">
                            <p className="text status">Note</p>
                            <p className="text stat">Stats of usage before Jan. 1st, 2022 have not been tracked. Fresh start for the new year!</p>
                        </div>
                    </div>
                    <p className="text stats-title">Audio Stats</p>
                    <div className="stats-section">
                        <div className="stat-col">
                            <p className="text status">{getMusicTimeStatTitle()}</p>
                            <p className="text stat">You've listened to {getOverallTimeStat(MUSIC_TIME_LISTENED_STAT_KEY)} of music!</p>
                        </div>
                        <div className="stat-col">
                            <p className="text status">{getMusicTopCategoryTitle()}</p>
                            <p className="text stat">Your top category is {getFavoriteTimeStat(FavoritesStats.MUSIC_CATEGORY)}.</p>
                        </div>
                        <div className="stat-col">
                            <p className="text status">{getSoundTimeStatTitle()}</p>
                            <p className="text stat">You've listened to {getOverallTimeStat(SOUNDS_TIME_LISTENED_STAT_KEY)} of ambient sounds and white noise.</p>
                        </div>
                        <div className="stat-col">
                            <p className="text status">{getTopSoundStatTitle()}</p>
                            <p className="text stat">Your top sound is: {getFavoriteTimeStat(FavoritesStats.SOUND)}</p>
                        </div>
                    </div>
                    <p className="text stats-title">Work Stats</p>
                    <div className="stats-section">
                        <div className="stat-col">
                            <p className="text status">{getCompletedTasksTitle()}</p>
                            <p className="text stat">You've completed {getWorkCompletionStat(TO_DO_ITEMS_COMPLETED_STAT_KEY)} tasks, and counting.</p>
                        </div>
                        <div className="stat-col">
                            <p className="text status">{getCompletedPomodoroTitle()}</p>
                            <p className="text stat">You've finished {getWorkCompletionStat(POMODORO_CYCLES_STAT_KEY)} pomodoro cycles.</p>
                        </div>
                    </div>
                    <div onClick={() => resetStats()} className="reset-button">
                        <p className="button-text">Reset Stats</p>
                    </div>
                </div>
            </div>
            <div className="footer">
                <DotAudioPlayerComponent/>
            </div>
        </div>
    );
}