import { useContext, useEffect } from 'react'

import './MainPage.scss';

import { HeaderComponent } from '../../components/Header/Header';
import { ToDoListComponent } from '../../components/ToDoList/ToDoList';
import { NotesComponent } from '../../components/Notes/Notes';
import { DotAudioPlayerComponent } from '../../components/DotAudioPlayer/DotAudioPlayer';
import { DotAudioSelectBlobComponent } from '../../components/DotAudioSelectBlob/DotAudioSelectBlob';
import { PomodoroTimerComponent, secondsToTimerString } from '../../components/PomodoroTimer/PomodoroTimer';
import { Helmet } from 'react-helmet';
import { DotNavigationContext, View } from '../../contexts/NavigationContext/NavigationContext';
import { useDotAudioContext } from '../../contexts/DotAudioContext/DotAudioContextCustomHook';
import { DotDataContext, PomodoroStatus } from '../../contexts/DotDataContext/DotDataContext';


export const MainPageComponent = (props: any) => {
  const {audioState} = useDotAudioContext();
  const {pomodoroState} = useContext(DotDataContext);

  const {setView} = useContext(DotNavigationContext);
    
  useEffect(() => {
  }, []);

  useEffect(() => {
  }, [pomodoroState])

  const changeView = (e: any, newView: View) => {
      e.preventDefault();
      window.history.pushState({view: newView, audioState: audioState}, "", `/${newView}`)
      setView(newView);
  }

  const getMainPageTitle = (titleString: string) => {
    if (!pomodoroState.timerOn) {
      return (<title>{`${titleString}`}</title>);
    }

    if (pomodoroState.timerOn && pomodoroState.pomodoroStatus === PomodoroStatus.WORKING) {
      return (<title>{`${secondsToTimerString(pomodoroState.secondsRemaining)} - Cycle ${pomodoroState.currentCycle} / ${pomodoroState.totalCycles}`}</title>)
    } else if (pomodoroState.timerOn && pomodoroState.pomodoroStatus !== PomodoroStatus.WORKING) {
      return (<title>{`Break: ${secondsToTimerString(pomodoroState.secondsRemaining)}`}</title>)
    }

    return <title>{'Dot Focus Error: Report to dev @Anduru_H'}</title>
  }

  const musicHelmet = () => {
    const canonicalSuffix = window.location.pathname === "/home" ? "home" : audioState.category.toLowerCase();
    switch (audioState.category) {
      case "Lofi":
        return (
          <Helmet defer={false}>
            {getMainPageTitle('aesthetic lo fi beats | online Pomodoro timer | white noise mixes | music stations | Dot Focus')}
            <meta
              name="description"
              content="now supporting Apple Music. mix ambience w/ lo fi hip hop. pro pomodoro timer. classical, jazz, and soundtrack music stations. natural sounds, white noise. time tracker, to do list, notes. all on a single website."
            />
            <link rel="canonical" href={`https://www.focuswithdot.com/${canonicalSuffix}`}/>
          </Helmet>
        );
      case "Jazz":
        return (
          <Helmet defer={false}>
            {getMainPageTitle('soothing jazz | online Pomodoro timer | white noise mixes | Dot Focus')}
            <meta
              name="description"
              content="mix ambience w/ smooth jazz. pro pomodoro timer. classical, lo fi hip hop, and soundtrack music stations. natural sounds, white noise. time tracker, to do list, notes. all on a single website."
            />
            <link rel="canonical" href={`https://www.focuswithdot.com/${canonicalSuffix}`}/>
          </Helmet>
        );
      case "Classical":
        return (
          <Helmet defer={false}>
            {getMainPageTitle('beautiful classical | online Pomodoro timer | white noise mixes | Dot Focus')}
            <meta
              name="description"
              content="mix ambience w/ premium classical. pro pomodoro timer. lo fi hip hop, jazz and soundtrack music stations. natural sounds, white noise. time tracker, to do list, notes. all on a single website."
            />
            <link rel="canonical" href={`https://www.focuswithdot.com/${canonicalSuffix}`}/>
          </Helmet>
        );
      case "Soundtrack":
        return (
          <Helmet defer={false}>
            {getMainPageTitle('motivating soundtracks | online Pomodoro timer | white noise mixes | Dot Focus')}
            <meta
              name="description"
              content="mix ambience w/ epic soundtracks. pro pomodoro timer. lo fi hip hop, jazz and classical music stations. natural sounds, white noise. time tracker, to do list, notes. all on a single website."
            />
            <link rel="canonical" href={`https://www.focuswithdot.com/${canonicalSuffix}`}/>
          </Helmet>
        );
    }
  }

  return (
    <div className="main-container">
      {musicHelmet()}
      <div className="header-container">
        <HeaderComponent
            soundDialogOpenedCallback={props.soundDialogOpenedCallback} 
            musicDialogOpenedCallback={props.musicDialogOpenedCallback}
        />
      </div>
      <div className="content-container">
        <div className="column">
        </div>
        <div className="column">
          <div className="central-container">
            <DotAudioSelectBlobComponent 
                soundDialogOpenedCallback={props.soundDialogOpenedCallback} 
                musicDialogOpenedCallback={props.musicDialogOpenedCallback}
                appleMusicDialogOpenedCallback={props.appleMusicDialogOpenedCallback}
            />
            <a href="/stats" onClick={(e) => changeView(e, View.STATS)} className="stats-button">
                <p className="text">Your Stats + Titles</p>
            </a>
          </div>
        </div>
      </div>
      <div className="footer">
        <DotAudioPlayerComponent />
      </div>
      <div className="side">
        <PomodoroTimerComponent/>
        <ToDoListComponent/>
        <NotesComponent/>
      </div>
    </div>
  );
}