/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import {DotDataContext, PomodoroStatus} from '../../contexts/DotDataContext/DotDataContext';
import './PomodoroTimer.scss';
import * as workerTimers from 'worker-timers';
import { PomodoroSettingsDialogComponent } from '../PomodoroSettingsDialog/PomodoroSettingsDialog';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../App';
import { DotAudioContext } from '../../contexts/DotAudioContext/DotAudioContext';

export const POMODORO_CYCLES_STAT_KEY = 'pomodoro-cycles-completed';

export const padZeroTimeValue = (timeValue: number, size: number = 2) => {
    let timeValueString = timeValue.toString();
    while (timeValueString.length < size) {
        timeValueString = "0" + timeValueString;
    }

    return timeValueString;
}

export const secondsToTimerString = (seconds: number) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds - mins * 60;

    return `${padZeroTimeValue(mins)}:${padZeroTimeValue(secs)}`;
}

export const PomodoroTimerComponent = () => {
    const {settingsState, pomodoroState, setPomodoroState} = useContext(DotDataContext);
    const {audioState} = useContext(DotAudioContext);

    const [settingsDialogOpen, setSettingsDialogOpen] = useState(false);

    useEffect(() => {}, [settingsDialogOpen])

    useEffect(() => {
        if (pomodoroState.timerOn) {
            const timeout = typeof(Worker) !== "undefined" 
            ? 
                workerTimers.setTimeout(() => {
                    executePomodoro();
                }, 1000)
            : setTimeout(() => {
                executePomodoro();
            }, 1000)

            return () => { 
                if (typeof(Worker) !== "undefined") {
                    workerTimers.clearTimeout(timeout as number);
                } else {
                    clearTimeout(timeout as NodeJS.Timeout);
                }
            }
        }
    }, [pomodoroState]);

    useEffect(() => {
        if (!settingsState.displaySettings.showPomodoro) {
            setPomodoroState({...pomodoroState, timerOn: false});
        }
    }, [settingsState.displaySettings.showPomodoro])

    const countDown = () => {
        const newSecondsRemaining = pomodoroState.secondsRemaining - 1;
        if (pomodoroState.pomodoroStatus === PomodoroStatus.WORKING && newSecondsRemaining === 0) {
            const pomodoroCompleted = localStorage.getItem(POMODORO_CYCLES_STAT_KEY);
            const newPomodoroCompleted = pomodoroCompleted ? parseInt(pomodoroCompleted) + 1 : 1;
            localStorage.setItem(POMODORO_CYCLES_STAT_KEY, newPomodoroCompleted.toString());
        }
        setPomodoroState({...pomodoroState, secondsRemaining: newSecondsRemaining});
    }

    const getNextPomodoroState = (alarm: boolean = true) => {
        const timerOn = 
            ((pomodoroState.pomodoroStatus === PomodoroStatus.LARGE_BREAK || pomodoroState.pomodoroStatus === PomodoroStatus.SMALL_BREAK) && pomodoroState.autoBeginTimer) || 
            (pomodoroState.pomodoroStatus === PomodoroStatus.WORKING && pomodoroState.autoBeginBreaks);

        const nextPomodoroStatus = getNextPomodoroStatus();

        if (nextPomodoroStatus !== PomodoroStatus.WORKING) {
            if (pomodoroState.pauseSoundsOnBreak) {
                audioState.selectedSoundTracks.filter(trackId => trackId.length > 0).map(trackId => {
                    const audio = document.getElementById(`${trackId}-audio`) as HTMLAudioElement;
                    if (audio) {
                        audio.pause();
                    }
                })
            }
        } else {
            if (pomodoroState.pauseSoundsOnBreak) {
                audioState.selectedSoundTracks.filter(trackId => trackId.length > 0).map(trackId => {
                    const audio = document.getElementById(`${trackId}-audio`) as HTMLAudioElement;
                    if (audio && !audio.paused) {
                        audio.play();
                    }
                })
            }
        }

        setPomodoroState({
            ...pomodoroState, 
            secondsRemaining: getNextSecondsRemaining(),
            currentCycle: getNextCycle(),
            pomodoroStatus: nextPomodoroStatus,
            timerOn: timerOn,
        });

        if (alarm) {
            const alertAudio = document.getElementById("alert-audio") as HTMLAudioElement;
            if (alertAudio) {
                alertAudio.load();
                alertAudio.play();
                setTimeout(() => {
                    alertAudio.load();
                    alertAudio.play();
                }, 2000);
            }
        }

        logEvent(analytics, 'pomodoro_cycle_completed');
    }

    const getNextSecondsRemaining = () => {
        // going from working to small break
        if (pomodoroState.pomodoroStatus === PomodoroStatus.WORKING && pomodoroState.currentCycle < pomodoroState.totalCycles) {
            return pomodoroState.shortBreakLength;
        } 
        // going from working to large break (finished cycles)
        else if (pomodoroState.pomodoroStatus === PomodoroStatus.WORKING && pomodoroState.currentCycle === pomodoroState.totalCycles) {
            return pomodoroState.longBreakLength;
        }
        else if (pomodoroState.pomodoroStatus === PomodoroStatus.SMALL_BREAK || pomodoroState.pomodoroStatus === PomodoroStatus.LARGE_BREAK) {
            return pomodoroState.pomodoroTimerLength;
        } 

        return pomodoroState.pomodoroTimerLength;
    }

    const getNextCycle = () => {
        if (pomodoroState.currentCycle < pomodoroState.totalCycles && (pomodoroState.pomodoroStatus === PomodoroStatus.SMALL_BREAK || pomodoroState.pomodoroStatus === PomodoroStatus.LARGE_BREAK)) {
            return pomodoroState.currentCycle + 1;
        } 
        else if (pomodoroState.pomodoroStatus === PomodoroStatus.WORKING) {
            return pomodoroState.currentCycle;
        }
        else {
            return 0;
        }
    }

    const getNextPomodoroStatus = () => {
        if (pomodoroState.pomodoroStatus === PomodoroStatus.WORKING && pomodoroState.currentCycle < pomodoroState.totalCycles) {
            return PomodoroStatus.SMALL_BREAK;
        }
        else if (pomodoroState.pomodoroStatus === PomodoroStatus.WORKING && pomodoroState.currentCycle >= pomodoroState.totalCycles) {
            return PomodoroStatus.LARGE_BREAK;
        }
        else if (pomodoroState.pomodoroStatus === PomodoroStatus.SMALL_BREAK || pomodoroState.pomodoroStatus === PomodoroStatus.LARGE_BREAK) {
            return PomodoroStatus.WORKING;
        }

        return PomodoroStatus.WORKING;
    }

    const executePomodoro = () => {
        // if there is still time on the clock, count down
        if (pomodoroState.secondsRemaining > 0) {
            countDown();
        } else {
            getNextPomodoroState();
        }
    }

    const getTimerCount = () => {
        return secondsToTimerString(pomodoroState.secondsRemaining);
    }

    const getPomodoroSubtext = () => {
        switch (pomodoroState.pomodoroStatus) {
            case PomodoroStatus.WORKING:
                return `Cycle ${pomodoroState.currentCycle} of ${pomodoroState.totalCycles}`;
            case PomodoroStatus.SMALL_BREAK:
                return `Break time - you deserve it! Or skip it...`;
            case PomodoroStatus.LARGE_BREAK:
                return `Take a large break - nice! Or, skip that thing...`;
        }
    }

    const changeToNextCycle = () => {
        if (pomodoroState.currentCycle === pomodoroState.totalCycles) {
            setPomodoroState({...pomodoroState, currentCycle: 0});
        } else {
            setPomodoroState({...pomodoroState, currentCycle: pomodoroState.currentCycle + 1});
        }
    }

    const changeToPreviousCycle = () => {
        if (pomodoroState.currentCycle === 0) {
            setPomodoroState({...pomodoroState, currentCycle: pomodoroState.totalCycles});
        } else {
            setPomodoroState({...pomodoroState, currentCycle: pomodoroState.currentCycle - 1});
        }
    }

    const restartPomodoro = () => {
        let resetSeconds: number;
        switch (pomodoroState.pomodoroStatus) {
            case PomodoroStatus.LARGE_BREAK:
                resetSeconds = pomodoroState.longBreakLength;
                break;
            case PomodoroStatus.SMALL_BREAK: 
                resetSeconds = pomodoroState.shortBreakLength;
                break;
            case PomodoroStatus.WORKING:
            default:
                resetSeconds = pomodoroState.pomodoroTimerLength;
                break;
        }

        setPomodoroState({...pomodoroState, secondsRemaining: resetSeconds})
    }

    const closeDialog = () => {
        setSettingsDialogOpen(false);
    }

    if (settingsState.displaySettings.showPomodoro) {
        return (
            <>
            { settingsDialogOpen 
                ?
                  <PomodoroSettingsDialogComponent closeDialogCallback={closeDialog}/>
                : null
            }
            <div className="pomodoro-container">
                <div className="pomodoro-timer-row">
                    {pomodoroState.timerOn 
                        ? <svg className="pomodoro-icon" onClick={() => setPomodoroState({...pomodoroState, timerOn: false})} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="hsl(40, 100%, 98%)" stroke="hsl(40, 100%, 98%)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><rect x="6" y="4" width="4" height="16"></rect><rect x="14" y="4" width="4" height="16"></rect></svg>    
                        : <svg className="pomodoro-icon" onClick={() => setPomodoroState({...pomodoroState, timerOn: true})} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="hsl(40, 100%, 98%)" stroke="hsl(40, 100%, 98%)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><polygon points="5 3 19 12 5 21 5 3"></polygon></svg>
                    }
                    <p className="text pomodoro-time unselectable">{getTimerCount()}</p>
                    <div className="pomodoro-timer-settings-col">
                        <div onClick={() => setSettingsDialogOpen(true)} className="edit-pomodoro-button">
                            <p className="text button-text">Edit</p>
                        </div>
                        <svg onDoubleClick={() => restartPomodoro()} className="restart-pomodoro-icon" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" width="24px" height="24px">    <path d="M 2 2 L 4.9394531 4.9394531 C 3.1262684 6.7482143 2 9.2427079 2 12 C 2 17.514 6.486 22 12 22 C 17.514 22 22 17.514 22 12 C 22 6.486 17.514 2 12 2 L 12 4 C 16.411 4 20 7.589 20 12 C 20 16.411 16.411 20 12 20 C 7.589 20 4 16.411 4 12 C 4 9.7940092 4.9004767 7.7972757 6.3496094 6.3496094 L 9 9 L 9 2 L 2 2 z"/></svg>
                    </div>
                </div>
                <div className="pomodoro-cycle-row">
                    {pomodoroState.pomodoroStatus === PomodoroStatus.WORKING
                        ? <svg xmlns="http://www.w3.org/2000/svg" onClick={() => changeToPreviousCycle()} className="change-cycle-icon" viewBox="0 0 24 24"><polyline points="15 18 9 12 15 6"></polyline></svg>
                        : null
                    }
                    <p className="text pomodoro-cycle unselectable">{getPomodoroSubtext()}</p>
                    {pomodoroState.pomodoroStatus === PomodoroStatus.WORKING
                        ? <svg xmlns="http://www.w3.org/2000/svg" onClick={() => changeToNextCycle()} className="change-cycle-icon" viewBox="0 0 24 24"><polyline points="9 18 15 12 9 6"></polyline></svg>
                        : null
                    }
                    <svg className="pomodoro-skip-icon" onDoubleClick={() => getNextPomodoroState(false)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="hsl(40, 100%, 98%)" stroke="hsl(40, 100%, 98%)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><polygon points="5 4 15 12 5 20 5 4"></polygon><line x1="19" y1="5" x2="19" y2="19"></line></svg>
                </div>
            </div>
            </>
        );
    } else {
        return null;
    }
}