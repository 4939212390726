import { useContext, useEffect, useState } from 'react';

import {toast, ToastContainer} from 'react-toastify';

import { DotNavigationContext, View } from './contexts/NavigationContext/NavigationContext';
import { SettingsPageComponent } from './page-components/SettingsPage/SettingsPage';
import { MainPageComponent } from './page-components/MainPage/MainPage';

import { openDB } from 'idb';

import './App.scss';
import 'react-toastify/dist/ReactToastify.min.css';

import { ColorPalette, DotDataContext } from './contexts/DotDataContext/DotDataContext';
import { AboutPageComponent } from './page-components/AboutPage/AboutPage';
import { ToDoItem } from './components/ToDoList/ToDoList';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { ContactPageComponent } from './page-components/ContactPage/ContactPage';
import { StatsPageComponent } from './page-components/StatsPage/StatsPage';
import { DotAudioSelectDialogComponent } from './components/DotAudioSelectDialog/DotAudioSelectDialog';
import { DotSoundSelectDialogComponent } from './components/DotAudioSelectDialog/DotSoundSelectDialog';
import { DotAppleMusicPlaylistDialogComponent } from './components/DotAppleMusicPlaylistDialog/DotAppleMusicPlaylistDialog';
import { DotLoadingComponent } from './components/DotLoadingComponent/DotLoadingComponent';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBE5MfeeKbuhtzmkQNFQxXrnhjNsXWgehU",
  authDomain: "dot-focus.firebaseapp.com",
  projectId: "dot-focus",
  storageBucket: "dot-focus.appspot.com",
  messagingSenderId: "990611682957",
  appId: "1:990611682957:web:704c8e5b18ca1eeaf294c4",
  measurementId: "G-CC8EY5CJ49"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);

export const idb = {
  db: () => openDB('dot-focus-idb', 1, {
    upgrade: (db, oldVersion) => {
      if (oldVersion === 0) {
        db.createObjectStore('notes');
        const toDoItemStore = db.createObjectStore('to-do-items');
        db.createObjectStore('settings');

        const defaultItems: ToDoItem[] = [
          {
            value: 'fully customizable pomodoro timer...',
            complete: true,
            id: 'default3',
          },
          {
            value: 'work and study to chill music',
            complete: true,
            id: 'default0',
          },
          {
            value: 'relax to ambience, or calm nature sounds',
            complete: true,
            id: 'default1',
          },          
          {
            value: 'start focusing today...',
            complete: true,
            id: 'default2',
          },
        ]

        toDoItemStore.put(defaultItems, 'items');
      } 
    }
  })
}

export const backgroundShapeColors: Map<ColorPalette, string[]> = new Map([
  [ColorPalette.DEFAULT, ['hsl(334, 100%, 94%)', 'hsl(183, 100%, 46%)', 'hsl(209, 100%, 65%)']],
  [ColorPalette.DARK_MODE, ['hsl(334, 90%, 94%)', 'hsl(183, 100%, 46%)', 'hsl(209, 100%, 65%)']]
]);

const App = () => {
  const {view} = useContext(DotNavigationContext);
  const {settingsState, loadingState} = useContext(DotDataContext);
  
  const [musicDialogOpen, setMusicDialogOpen] = useState(false);
  const [soundDialogOpen, setSoundDialogOpen] = useState(false);
  const [appleMusicDialogOpen, setAppleMusicDialogOpen] = useState(false);

  useEffect(() => {
    // TODO: REMOVE THIS SOON - Use this to add news. Next update, remove this key and add a new key.
    /*
    const appleMusicNews = localStorage.getItem('apple-music-news-apr-3');
    if (!appleMusicNews) {
      toast('2022 Apr 03 Update: Apple Music BETA is launched. Want Spotify? Contact me with requests!', {position: toast.POSITION.TOP_CENTER});
      localStorage.setItem('apple-music-news-apr-3', 'displayed');
    }
    */
 
    /*
    else {
      const toastDisplayedCount = parseInt(localStorage.getItem('toast-display-count') || '0');
      if (toastDisplayedCount % 7 === 1) {
        const toastContent = () => {
          return (
            <div className="toast-div">
              <a className="contact-icon" href="https://twitter.com/Anduru_H" rel="noreferrer" target="_blank">
                <svg xmlns="http://www.w3.org/2000/svg" stroke="hsl(210, 100%, 60%)" fill="hsl(210, 100%, 60%)" strokeWidth={3} width="32px" height="32px" viewBox="0 0 24 24"><path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path></svg>                        
              </a>   
              <p className="text toast-text">I really appreciate any comments and shares! You can reach me on Twitter.</p>
            </div>
          );
        }
  
        toast(toastContent(), {position: toast.POSITION.TOP_CENTER});
      }
      
      localStorage.setItem('toast-display-count', (toastDisplayedCount + 1).toString())
      */

    // Too lazy to actually program this - but this should emit an event when the app is just running in the background - listening to sounds.
    const usageInterval = setInterval(() => {
      logEvent(analytics, "probably_listening_to_sound");
    }, 300000)

    const musicKitDialogScrim = document.createElement('musickit-dialog-scrim');
    const musicKitDialog = document.createElement('musicKit-dialog');
    const musicKitDialogStyle = document.createElement('musicKit-dialog-style');

    musicKitDialog.style.display = 'none';
    musicKitDialogScrim.style.display = 'none';

    return () => {
      clearInterval(usageInterval);
    }
  }, [])

  useEffect(() => {
    const getBackground = () => {
      switch (settingsState.backgroundSettings.colorPalette) {
        case ColorPalette.DARK_MODE:
          return 'linear-gradient(hsl(0, 0%, 10%), hsl(0, 0%, 20%))';
        default:
          return 'linear-gradient(hsl(215, 72%, 53%), hsl(225, 66%, 30%))';
      }
    }

    const appContainerElem = document.getElementById("app-container");
    if (appContainerElem) {
      appContainerElem.style.background = getBackground();
    }
  }, [settingsState.backgroundSettings.colorPalette])

  useEffect(() => {
  }, [view, settingsState])

  useEffect(() => {
    if (!('indexedDB' in window)) {
      alert('Browser does not support IndexedDB - item persistence is disabled.');
    } else {
      idb.db();
    }

    const getRandomBetween = (num1: number, num2: number): number => {
      return Math.random() * (num2 - num1) + num1;
    }

    const MIN_ANIMATION_DURATION = 27;
    const MAX_ANIMATION_DURATION = 67;
    const MIN_ANIMATION_DELAY = 0;
    const MAX_ANIMATON_DELAY = 0;

    const getBackgroundShapeColors = () => {
      return backgroundShapeColors.get(settingsState.backgroundSettings.colorPalette) || ['hsl(334, 100%, 94%)', 'hsl(183, 100%, 46%)', 'hsl(209, 100%, 65%)'];
    }

    const colors = getBackgroundShapeColors();
    
    for (let i = 0; i < settingsState.backgroundSettings.dotCount; i++) {
      const BG_SHAPE_PREFIX = "bg-shape-";
      const BG_SHAPE = document.getElementById(`${BG_SHAPE_PREFIX}${i+1}`);

      if (BG_SHAPE) {
        BG_SHAPE.style.color = `${colors[Math.round(getRandomBetween(0, colors.length - 1))]}`;
        BG_SHAPE.style.top = `${getRandomBetween(25, 75)}%`;
        BG_SHAPE.style.left = `${getRandomBetween(25, 75)}%`;

        const animation = `move-background-shape-${Math.round(getRandomBetween(0, 1))}`;
        BG_SHAPE.style.animation = `${animation} linear infinite`;
        BG_SHAPE.style.animationDuration = `${getRandomBetween(MIN_ANIMATION_DURATION, MAX_ANIMATION_DURATION)}s`
        BG_SHAPE.style.animationDelay = `${getRandomBetween(MIN_ANIMATION_DELAY, MAX_ANIMATON_DELAY)}s`;
        BG_SHAPE.style.transformOrigin = `${getRandomBetween(0, 50) - 25}vw ${getRandomBetween(0, 50) - 25} vh`;

        const blurRadius = `${(getRandomBetween(0, 1) + 0.5) * 20 * 0.5}vmin`;
        const multiplier = getRandomBetween(0, 1) > 1 ? -1 : 1;
        BG_SHAPE.style.boxShadow = `${20 * 2 * multiplier}vmin 0 ${blurRadius} currentColor`;
      }
    }
  }, [settingsState.backgroundSettings]);

  useEffect(() => {
  }, [musicDialogOpen, soundDialogOpen, appleMusicDialogOpen]);

  useEffect(() => {}, [loadingState])

  const musicDialogClosedCallback = () => {
    setMusicDialogOpen(false);
  }

  const musicDialogOpenedCallback = () => {
    setMusicDialogOpen(true);
  }

  const soundDialogClosedCallback = () => {
    setSoundDialogOpen(false);
  }

  const soundDialogOpenedCallback = () => {
    setSoundDialogOpen(true);
  }

  const appleMusicDialogClosedCallback = () => {
    setAppleMusicDialogOpen(false);
  }

  const appleMusicDialogOpenedCallback = () => {
    setAppleMusicDialogOpen(true);
  }

  const renderBackgroundShapes = () => {
    const array = [...Array(settingsState.backgroundSettings.dotCount)];
    return array.map((item, idx) => {
      const itemId = `bg-shape-${idx+1}`;
      return <span id={itemId} key={idx+1} className="bubble"></span>
    })
  }

  const renderView = () => {
    switch (view) {
      case View.SETTINGS:
        return (
          <SettingsPageComponent
            soundDialogOpenedCallback={soundDialogOpenedCallback} 
            musicDialogOpenedCallback={musicDialogOpenedCallback}
          />
        );
      case View.ABOUT:
        return (
          <AboutPageComponent
            soundDialogOpenedCallback={soundDialogOpenedCallback} 
            musicDialogOpenedCallback={musicDialogOpenedCallback}
          />
        );
      case View.CONTACT:
        return (
          <ContactPageComponent
            soundDialogOpenedCallback={soundDialogOpenedCallback} 
            musicDialogOpenedCallback={musicDialogOpenedCallback}
          />
        );
      case View.STATS:
        return (
          <StatsPageComponent
            soundDialogOpenedCallback={soundDialogOpenedCallback} 
            musicDialogOpenedCallback={musicDialogOpenedCallback}
          />
        );
      case View.MAIN: 
      default:
        return (
          <MainPageComponent
            soundDialogOpenedCallback={soundDialogOpenedCallback} 
            musicDialogOpenedCallback={musicDialogOpenedCallback}
            appleMusicDialogOpenedCallback={appleMusicDialogOpenedCallback}
          />
        );    
      }
  }

  return (
    <div id="app-container" className="container">
      <DotLoadingComponent 
        colorScheme={settingsState.backgroundSettings.colorPalette} 
        message={loadingState.loadingMessage}
      />
      <DotAppleMusicPlaylistDialogComponent
        soundSelectProps={
          {
            dialogOpened: appleMusicDialogOpen,
            dialogClosedCallback: appleMusicDialogClosedCallback
          }
        }
      />
      <DotAudioSelectDialogComponent 
        musicDialogClosedCallback={musicDialogClosedCallback} 
        musicDialogOpened={musicDialogOpen}
      />
      <DotSoundSelectDialogComponent 
        soundSelectProps={
          {
            dialogOpened: soundDialogOpen,
            dialogClosedCallback: soundDialogClosedCallback
          }
        }
      />
      <ToastContainer/>
      {renderBackgroundShapes()}
      {renderView()}
    </div>
  );
}

export default App;
