
import { useEffect, useContext } from 'react';
import { useDotAudioContext } from '../../contexts/DotAudioContext/DotAudioContextCustomHook';
import { ColorPalette, DotDataContext } from '../../contexts/DotDataContext/DotDataContext';
import { DotTrackCategories, DotTrackSelectComponent } from '../DotTrackSelect/DotTrackSelect';
import { AudioDialogColorPalette } from './DotAudioSelectDialog';
import './DotSoundSelectDialog.scss';

export interface DotSoundSelectProps {
    dialogOpened: boolean;
    dialogClosedCallback: () => void
}

export const DotSoundSelectDialogComponent = (props: {soundSelectProps: DotSoundSelectProps}) => {
    const {audioState, selectedSoundCategory, setSelectedSoundCategory} = useDotAudioContext();

    const {settingsState} = useContext(DotDataContext);

    useEffect(() => {
        const getColorPalette = (): AudioDialogColorPalette => {
          switch (settingsState.backgroundSettings.colorPalette) {
            case ColorPalette.DARK_MODE:
              return {
                background: 'hsl(0, 0%, 20%)',
                subtitleColor: 'hsl(40, 100%, 98%)'
              }
            default:
              return {
                background: 'hsl(210, 100%, 60%)',
                subtitleColor: 'hsl(208, 100%, 89%)',
              }
          }
        }
    
        const maskElem = document.getElementById('sound-dialog-mask');
        if (maskElem) {
          maskElem.style.background = getColorPalette().background;
    
          const subtitleTextElems = document.getElementsByClassName("sound-card-text-subtitle") as HTMLCollectionOf<HTMLElement>;
          for (let i = 0; i < subtitleTextElems.length; i++) {
            const subtitleElem = subtitleTextElems[i];
            if (subtitleElem) {
              subtitleElem.style.color = getColorPalette().subtitleColor;
            }
          }
        }
      }, [settingsState.backgroundSettings.colorPalette, props.soundSelectProps.dialogOpened]);

    useEffect(() => {
        const natureIcon = document.getElementById("nature");
        const ambientIcon = document.getElementById("ambient");
        const meditationIcon = document.getElementById("meditation");

        if (selectedSoundCategory === "") {
            ambientIcon?.classList.remove("ambient-selected");
            natureIcon?.classList.remove("nature-selected");
            meditationIcon?.classList.remove('meditation-selected');
        } else if (selectedSoundCategory === "Nature") {
            natureIcon?.classList.add('nature-selected');
            ambientIcon?.classList.remove("ambient-selected");
            meditationIcon?.classList.remove('meditation-selected');
        } else if (selectedSoundCategory === "Ambient") {
            ambientIcon?.classList.add('ambient-selected');
            natureIcon?.classList.remove("nature-selected");
            meditationIcon?.classList.remove('meditation-selected');
        } else if (selectedSoundCategory === "Meditative") {
            meditationIcon?.classList.add('meditation-selected');
            ambientIcon?.classList.remove("ambient-selected");
            natureIcon?.classList.remove("nature-selected");
        }
    }, [selectedSoundCategory]);
    
    const selectSoundCategory = (soundCategorySelected: DotTrackCategories) => {
        if (soundCategorySelected === selectedSoundCategory) {
            setSelectedSoundCategory("");
        } else {
            setSelectedSoundCategory(soundCategorySelected);
        }
    }

    const getSoundsSelectedCount = () => {
        return audioState.selectedSoundTracks.length - 1;
    }

    const closeDialog = () => {
        props.soundSelectProps.dialogClosedCallback();
        setSelectedSoundCategory("");
    }

    if (props.soundSelectProps.dialogOpened) {
        return (
            <>
                <div id="sound-dialog-mask" className="sound-dialog-mask"></div>
                <div className="sound-dialog-container">
                    <div className="sound-dialog-header">
                        <svg onClick={() => closeDialog()} xmlns="http://www.w3.org/2000/svg" className="sound-dialog-exit-icon" viewBox="0 0 24 24" fill="none" stroke="hsl(40, 100%, 98%)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                        <div className="text sound-dialog-header-text">
                            {selectedSoundCategory === ""
                                ? "Choose a category"
                                : `${getSoundsSelectedCount()} / 3 sounds added.`
                            }
                        </div>
                    </div>
                    <div className="sound-dialog-content">
                        <div onClick={() => selectSoundCategory("Nature")} className="sound-card-row">
                            <div id="nature" className="sound-card-background nature"></div>
                            <div className="sound-card-text-column">
                                <p className="text sound-card-text">Nature</p>
                                <p className="text sound-card-text-subtitle">
                                    Add the sounds of outdoors, at any time.
                                </p>
                            </div>
                        </div>
                        { selectedSoundCategory === "Nature" 
                            ? <DotTrackSelectComponent trackSelectProps={
                                {trackCategory: selectedSoundCategory}
                            }/>
                            : null
                        }
                        <div onClick={() => selectSoundCategory("Ambient")} className="sound-card-row">
                            <div id="ambient" className="sound-card-background ambient"></div>
                            <div className="sound-card-text-column">
                                <p className="text sound-card-text">Ambient</p>
                                <p className="text sound-card-text-subtitle">
                                    Cafes, cities, and the sounds of life.
                                </p>
                            </div>
                        </div>
                        { selectedSoundCategory === "Ambient" 
                            ? <DotTrackSelectComponent trackSelectProps={
                                {trackCategory: selectedSoundCategory}
                            }/>
                            : null
                        }
                        <div onClick={() => selectSoundCategory("Meditative")} className="sound-card-row">
                            <div id="meditation" className="sound-card-background meditation"></div>
                            <div className="sound-card-text-column">
                                <p className="text sound-card-text">Meditation</p>
                                <p className="text sound-card-text-subtitle">
                                    Serene sounds for the deepest focus.
                                </p>
                            </div>
                        </div>
                        { selectedSoundCategory === "Meditative" 
                            ? <DotTrackSelectComponent trackSelectProps={
                                {trackCategory: selectedSoundCategory}
                            }/>
                            : null
                        }
                    </div>
                    <div className="sound-dialog-footer"></div>
                </div>
            </>
        );
    } else {
        return null;
    } 
}