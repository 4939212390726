import { useContext, useEffect, useState } from "react";
import { DotAudioPlayerComponent } from "../../components/DotAudioPlayer/DotAudioPlayer";
import { HeaderComponent } from "../../components/Header/Header";

import './SettingsPage.scss';
import '../../App.scss';
import { DotNavigationContext, View } from "../../contexts/NavigationContext/NavigationContext";
import { DotSettings, DotDataContext, StorageOption } from "../../contexts/DotDataContext/DotDataContext";
import { Helmet } from "react-helmet";

export const SettingsPageComponent = (props: any) => {
    const { setView } = useContext(DotNavigationContext);
    const { settingsState, setSettingsState } = useContext(DotDataContext);

    const [storageOption, setStorageOption] = useState(settingsState.storageOption);
    const [dotsCount, setDotsCount] = useState(settingsState.backgroundSettings.dotCount);
    const [showNotes, setShowNotes] = useState(settingsState.displaySettings.showNotes);
    const [showToDoList, setShowToDoList] = useState(settingsState.displaySettings.showToDoList);
    const [showPomodoro, setShowPomodoro] = useState(settingsState.displaySettings.showPomodoro);
    const [chillModeOn, setChillModeOn] = useState(false);

    useEffect(() => {
    }, [chillModeOn]);

    const saveAndNavigateTo = (newView: View) => {
        const newSettings: DotSettings = {
            backgroundSettings: {
                dotCount: dotsCount,
                dotActivity: 1,
                colorPalette: settingsState.backgroundSettings.colorPalette
            },            
            storageOption: storageOption,
            displaySettings: {
                showNotes: showNotes,
                showToDoList: showToDoList,
                showPomodoro: showPomodoro
            }
        };

        // TODO: there has to be a better way to do this object equality
        if (
            settingsState.backgroundSettings.dotCount !== newSettings.backgroundSettings.dotCount ||
            settingsState.backgroundSettings.dotActivity !== newSettings.backgroundSettings.dotActivity ||
            settingsState.storageOption !== newSettings.storageOption ||
            settingsState.displaySettings.showNotes !== newSettings.displaySettings.showNotes ||
            settingsState.displaySettings.showToDoList !== newSettings.displaySettings.showToDoList ||
            settingsState.displaySettings.showPomodoro !== newSettings.displaySettings.showPomodoro
        ) {
            setSettingsState(newSettings);
        }

        window.history.back();
        setView(newView);
    }

    const changeStorageOption = (option: StorageOption) => {
        setStorageOption(option);
    }

    const toggleChillMode = () => {
        if (chillModeOn) {
            setShowPomodoro(true);
            setShowNotes(true);
            setShowToDoList(true);
            setChillModeOn(false);
        } else {
            setShowPomodoro(false);
            setShowNotes(false);
            setShowToDoList(false);
            setChillModeOn(true);
        }
    }

    const setNewDotsCount = (newCount: number) => {
        if (newCount <= 30 && newCount >= 0) {
            setDotsCount(newCount);
        }
    }

    const getDotsCount = () => {
        return dotsCount;
    }

    return (
        <div className="settings-container">
            <Helmet>
                <title>Dot Focus Settings</title>
                <link rel="canonical" href="https://www.focuswithdot.com/settings"/>
            </Helmet>
            <HeaderComponent
                soundDialogOpenedCallback={props.soundDialogOpenedCallback} 
                musicDialogOpenedCallback={props.musicDialogOpenedCallback}
            />
            <div className="content-container">
                <div className="settings-content-container">
                    <p className="text setting-header unselectable">Storage Options</p>
                    <div className="settings-row">
                        {storageOption === StorageOption.KEEP 
                            ? <div className="storage-option-radio-button-complete"></div>
                            : <div onClick={() => changeStorageOption(StorageOption.KEEP)} className="storage-option-radio-button"></div>
                        }
                        <p className="text settings-text unselectable">Keep to-do items and notes between sessions</p>
                    </div>
                    <div className="settings-row">
                        {storageOption === StorageOption.CLEAR 
                            ? <div className="storage-option-radio-button-complete"></div>
                            : <div onClick={() => changeStorageOption(StorageOption.CLEAR)} className="storage-option-radio-button"></div>
                        }                        
                        <p className="text settings-text unselectable">Clear to-do items and notes after exiting</p>
                    </div>

                    <p className="text setting-header unselectable">Display Settings</p>
                    <div className="settings-row">
                        {showNotes 
                            ? <div onClick={() => setShowNotes(false)} className="storage-option-radio-button-complete"></div>
                            : <div onClick={() => setShowNotes(true)} className="storage-option-radio-button"></div> 
                        }
                        <p className="text settings-text unselectable">Show notes display</p>
                    </div>
                    <div className="settings-row">
                    {showToDoList
                            ? <div onClick={() => setShowToDoList(false)} className="storage-option-radio-button-complete"></div>
                            : <div onClick={() => setShowToDoList(true)} className="storage-option-radio-button"></div> 
                        }
                        <p className="text settings-text unselectable">Show to do list display</p>
                    </div>
                    <div className="settings-row">
                    {showPomodoro
                            ? <div onClick={() => setShowPomodoro(false)} className="storage-option-radio-button-complete"></div>
                            : <div onClick={() => setShowPomodoro(true)} className="storage-option-radio-button"></div> 
                        }
                        <p className="text settings-text unselectable">Show pomodoro timer</p>
                    </div>

                    <div className="settings-row">
                    {chillModeOn
                            ? <div onClick={() => toggleChillMode()} className="storage-option-radio-button-complete"></div>
                            : <div onClick={() => toggleChillMode()} className="storage-option-radio-button"></div> 
                        }
                        <p className="text settings-text unselectable">Chill Mode on</p>
                    </div>

                    <p className="text setting-header unselectable">Background Settings</p>
                    <div className="settings-row centered">
                        <div className="operator-button">
                            <svg onClick={() => setNewDotsCount(dotsCount - 1)} className="operator-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><line x1="5" y1="12" x2="19" y2="12"></line></svg>
                        </div>
                        <div className="value-display-box">
                            <p className="text settings-text larger unselectable">{getDotsCount()} Dots Active</p>
                        </div>
                        <div className="operator-button">
                            <svg onClick={() => setNewDotsCount(dotsCount + 1)} className="operator-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
                        </div>
                    </div>

                    <div onClick={() => saveAndNavigateTo(View.MAIN)} className="save-button centered">
                        <p className="text button-text unselectable">Save</p>
                    </div>
                </div>
            </div>
            <DotAudioPlayerComponent/>
        </div>
    );
}