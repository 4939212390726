const BASE_APPLE_MUSIC_API_URL = "https://api.music.apple.com"
const BASE_LIBRARY_CATALOG_PATH = "v1/me/library/playlists"

export enum AppleMusicResourceType {
    LIBRARY_PLAYLIST = "library-playlists",
    LIBRARY_SONG = "library-songs",
}

// Not the exhaustive list, only properties that are useful.
// To explore / add more properties, make sure to 
// console.log the actual api response from Apple Music
export interface AppleMusicResource {
    attributes: {
        name: string;
        albumName?: string;
        artistName?: string;
        durationInMillis?: number;
        trackNumber?: number;
        artwork?: {
            height: number;
            url: string;
            width: number;
        };
    };
    id: string;
    href: string;
    type: AppleMusicResourceType;
    relationships?: {
        tracks: AppleMusicTracks;
        type: AppleMusicResourceType;
    };
}

export interface AppleMusicTracks {
    // TODO: Maybe split this off into a more descriptive type so it's not an actual recursive interface?
    data: AppleMusicResource[];
    meta: {
        total: number;
    };
    href: string;
    next: string;
}

const appleMusicAuthorization = (musicKit: MusicKit.MusicKitInstance): Promise<string> | undefined => {
    if (musicKit) {
        return musicKit.authorize();
    }
}

const getApiHeaders = (musicKit: MusicKit.MusicKitInstance) => {
    return new Headers({
        Authorization: 'Bearer ' + musicKit.developerToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Music-User-Token': '' + musicKit.musicUserToken
    })
}

const getPlaylists = (musicKitInstance: MusicKit.MusicKitInstance): Promise<AppleMusicResource[]> => {
    return musicKitInstance.api.library.playlists(null, {limit: 100}).then(playlists => {
        return playlists.map(playlist => {
            return playlist as unknown as AppleMusicResource
        });
    });
}

const getPlaylistNames = (playlists: AppleMusicResource[]): string[] => {
    return playlists.map(playlist => playlist.attributes.name);
}

const getPlaylistSongs = (musicKitInstance: MusicKit.MusicKitInstance, playlistId: string): Promise<AppleMusicTracks | undefined> => {
    return musicKitInstance.api.library.playlist(playlistId, {include: 'tracks'}).then(tracks => {
        const appleMusicTracks = tracks as unknown as AppleMusicResource;
        return appleMusicTracks.relationships?.tracks;
    });
}

const getPlaylistSongCount = (musicKitInstance: MusicKit.MusicKitInstance, playlistId: string): Promise<number | undefined> => {
    return musicKitInstance.api.library.playlist(playlistId, {include: 'tracks'}).then(tracks => {
        const appleMusicTracks = tracks as unknown as AppleMusicResource;
        return appleMusicTracks.relationships?.tracks.meta.total;
    });
}

// TODO: Add loop to access more than the first 100 songs
const getPlaylistSongNames = (playlistTracks: AppleMusicTracks) => {
    return playlistTracks.data.map(track => track.attributes.name);
}

const getAllPlaylistSongs = async (musicKit: MusicKit.MusicKitInstance, playlistId: string) => {
    let songs: AppleMusicResource[] = [];
    let songCount = 0;

    await getPlaylistSongCount(musicKit, playlistId)
        .then(count => {
            if (count) {
                songCount = count;
            }
        });

    const headers = getApiHeaders(musicKit);
    const basePlaylistTracksUrl = `${BASE_APPLE_MUSIC_API_URL}/${BASE_LIBRARY_CATALOG_PATH}/${playlistId}/tracks`;

    const fetchSongsPromises: Promise<void>[] = [];

    for (let i = 0; i <= songCount / 100; i++) {
        const offset = i * 100;
        const fetchSongPromise = fetch(`${basePlaylistTracksUrl}?offset=${offset}`, {headers: headers})
            .then(response => response.json())
            .then((tracks: AppleMusicTracks) => {
                tracks.data.forEach(track => songs.push(track));
            });

        fetchSongsPromises.push(fetchSongPromise);
    }

    await Promise.all(fetchSongsPromises);

    return songs;
}

export const AppleMusicHelper = {
    appleMusicAuthorization,
    getPlaylists,
    getPlaylistNames,
    getPlaylistSongs,
    getPlaylistSongNames,
    getPlaylistSongCount,
    getAllPlaylistSongs
}