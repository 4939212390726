
import { useContext, useEffect, useState } from 'react';
import { DotAudioPlayerComponent } from '../../components/DotAudioPlayer/DotAudioPlayer';
import { HeaderComponent } from '../../components/Header/Header';
import { DotNavigationContext, View } from '../../contexts/NavigationContext/NavigationContext';
import { Helmet } from "react-helmet";
import './AboutPage.scss';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../App';

export enum AboutSection {
    ABOUT = 'About',
    FEATURES = 'Features',
    WHY = 'Why',
    CREDITS = 'Credits'
}

export const AboutPageComponent = (props: any) => {
    const [currSection, setCurrSection] = useState(AboutSection.ABOUT);
    const {setView} = useContext(DotNavigationContext);

    if (window) {
        window.onpopstate = function(e: PopStateEvent) {
            logEvent(analytics, 'pop_state_occurred');
            if (e.state.section) {
                setCurrSection(e.state.section);
            } else if (e.state.view !== View.ABOUT) {
                setView(e.state.view);
            }
        }
    }

    useEffect(() => {
        if (window.location.pathname) {
            const path = window.location.pathname;
            switch (path) {
                case "/about/About":
                    setCurrSection(AboutSection.ABOUT);
                    window.history.replaceState({view: View.ABOUT, section: AboutSection.ABOUT}, "", "/about/About");
                    break;
                case "/about/Features": 
                    setCurrSection(AboutSection.FEATURES);
                    window.history.replaceState({view: View.ABOUT, section: AboutSection.FEATURES}, "", "/about/Features");
                    break;
                case "/about/Why":
                    setCurrSection(AboutSection.WHY);
                    window.history.replaceState({view: View.ABOUT, section: AboutSection.WHY}, "", "/about/Why");
                    break;
                case "/about/Credits":
                    setCurrSection(AboutSection.CREDITS);
                    window.history.replaceState({view: View.ABOUT, section: AboutSection.CREDITS}, "", "/about/Credits");
                    break;
            }
        }
    }, [])

    useEffect(() => {
        const headerElem = document.getElementById(currSection);

        if (headerElem) {
            headerElem.style.color = 'hsl(209, 100%, 79%)';
            headerElem.style.borderBottom = '4px solid hsl(209, 100%, 79%)';
        }

        logEvent(analytics, "page_view");

        return () => {
            if (headerElem) {
                headerElem.style.color = 'hsl(207, 100%, 95%)';
                headerElem.style.borderBottom = 'none';
            }
        }
    }, [currSection]);

    const headerClicked = (e: any, section: AboutSection) => {
        e.preventDefault();
        window.history.pushState({view: View.ABOUT, section: section}, "", `/about/${section}`);

        setCurrSection(section);
    }

    const renderAboutContent = () => {
        switch (currSection) {
            case AboutSection.ABOUT:
                return (
                    <div className="section-container">
                        <Helmet>
                            <title>About Dot Focus</title>
                            <meta
                                name="description"
                                content="Now supporting Apple Music. Dot Focus is designed for study, work, relaxation, and sleep with the best music, beats, noises, and sounds, along with productivity boosting tools."
                            />
                            <link rel="canonical" href="https://www.focuswithdot.com/about/About"/>
                        </Helmet>
                        <section>
                            <h2 className="text section-header unselectable">The Goal</h2>
                            <p className="text section-text">Improve focus while studying, working, and relaxing. Enhance sleep.</p>
                        </section>
                        <section>
                            <h2 className="text section-header unselectable">Audio for Study, Work, and Sleep</h2>
                            <ul className="text section-text">
                                <li>Listen to work songs and study music, that are equally soothing for sleep. 
                                    Dot Focus now supports Apple Music playlists, as well as built-in stations with multiple genres, including lo fi music, classical, jazz, and epic soundtracks.
                                </li>
                                <li>High quality ambient sound and noise generators.</li>
                                <li>Immersive nature sounds, such as ocean sounds, forest sounds, calming bird noises and more relaxing audio.</li>
                                <li>The familiar ambient sounds of daily life, such as city sounds, airplane cabin noise, and inspiring cafe ambience.</li>
                                <li>Other cozy white noise generators including the sounds of a rainy day, a dim ceiling fan, and crackling fireplace sounds.</li>
                                <li>Combine ambient sounds and white noise with music for the perfect environment to work to, study to, relax to, and sleep to.</li>
                            </ul>
                        </section>
                    </div>
                );
            case AboutSection.FEATURES:
                return (
                    <div className="section-container">
                        <Helmet>
                            <title>Dot Focus Features</title>
                            <meta
                                name="description"
                                content="Now supporting Apple Music. Built to help you reach the zone. Tracks for focus, calming audio, a to-do list, pomodoro timer, notes, and so much more."
                            />
                            <link rel="canonical" href="https://www.focuswithdot.com/about/Features"/>
                        </Helmet>
                        <section>
                            <h2 className="text section-header unselectable">Apple Music Support</h2>
                            <p className="text section-text">Mix your favorite Apple Music playlists with our curated background sounds.</p>
                        </section>
                        <section>
                            <h2 className="text section-header unselectable">Listen to curated, calming music</h2>
                            <p className="text section-text">Select tracks from 4 different genres, like lofi music beats, classical music, and smooth jazz.</p>
                        </section>
                        <section>
                            <h2 className="text section-header unselectable">Check your stats</h2>
                            <p className="text section-text">Get motivated! Track your time and productivity with our fun achievements and titles.</p>
                        </section>
                        <section>
                            <h2 className="text section-header unselectable">To Do List</h2>
                            <p className="text section-text">List and check off your most important tasks. List items and notes are automatically stored locally.</p>
                        </section>
                        <section>
                            <h2 className="text section-header unselectable">Notes</h2>
                            <p className="text section-text">Add detailed notes to guide your current ideas.</p>
                        </section>
                        <section>
                            <h2 className="text section-header unselectable">Pomodoro Timer</h2>
                            <p className="text section-text">A fully customizable, pro Pomodoro timer for fans of the Pomodoro technique!</p>
                        </section>
                        <section>
                            <h2 className="text section-header unselectable">Dark Mode</h2>
                            <p className="text section-text">For the Dark Mode fans...it's here!</p>
                        </section>
                        <section>
                            <h2 className="text section-header unselectable">Chill / Sleep Mode</h2>
                            <p className="text section-text">You don't have to get things done - turn on ambient sounds and relaxing music, then just enjoy life.</p>
                        </section>
                    </div>
                );
            case AboutSection.WHY:
                return (
                    <div className="section-container">
                        <Helmet>
                            <title>Why Dot Focus?</title>
                            <meta
                                name="description"
                                content="Combine ambience and high quality white noise with aesthetic lo fi or other genres. Robust tools for productivity, and fun user statistics."
                            />
                            <link rel="canonical" href="https://www.focuswithdot.com/about/Why"/>
                        </Helmet>
                        <div className="text section-text">
                            <section>
                                Dot Focus is a lightweight, central 
                                hub for productivity, crafted around a few key features: audio, to do lists,
                                notes, and a pomodoro timer. 
                            </section>
                            <br/>
                            <section >
                                There are a few similar websites, such as lofi cafe, mynoise.net, noisli, and others, but none of them hit the right spot for me. Dot Focus comes with the 
                                previously mentioned productivity features,
                                has handpicked, curated music, and sounds over 6 different genres (with plans to add more).
                            </section>
                            <br/>
                            <section>
                                With the Dot Focus website, you can:
                                <ul>
                                    <li>Find the best chill music for work or sounds to study to.</li>
                                    <li>Play a curated collection of lofi study music, lo fi hip hop, lo fi beats and other genres of music.</li>
                                    <li>Listen to the calming sounds of nature or ambient noise to relax or sleep.</li>
                                    <li>Mix music and background ambient sounds together for your perfect vibe.</li>
                                    <li>Keep track of tasks with the built-in to do list and notes.</li>
                                    <li>...and more!</li>
                                </ul>
                            </section>
                        </div>
                    </div>
                );
            case AboutSection.CREDITS:
                return (
                    <div className="section-container">
                        <Helmet>
                            <title>Dot Focus Credits</title>
                            <meta
                                name="description"
                                content="Focus better with Dot Focus, with our immersive and calm aesthetic inspired many great creators."
                            />
                            <link rel="canonical" href="https://www.focuswithdot.com/about/Credits"/>
                        </Helmet>
                        <section className="text section-text">
                            Dot Focus would not be possible without the artists who created the music and sounds, 
                            as well as the guides used in the development of the web app, both visually and behind the scenes.
                            These acknowledgements will be provided via <a className="roadmap-link" href="https://docs.google.com/spreadsheets/d/1nE6gQUb9j5oSS8NF9sa6Szur01cjfAn14Udkq1LUTCc/edit?usp=sharing" rel="noreferrer" target="_blank">Google Sheets</a>.
                            <br />
                            <br />
                            High on the roadmap is a way to display that credit while
                            a specific sound or song is playing in real time.
                            <br />
                            <br />
                            Also, special thanks to Brad of <a className="text roadmap-link" href="https://whyp.it" rel="noreferrer" target="_blank">whyp.it </a>
                            for providing valuable guidance and advice on CDN usage, audio streaming, and overall tech stack!
                        </section>
                    </div>
                );
        }
    }

    return (
        <div className="about-container">
            <HeaderComponent
                soundDialogOpenedCallback={props.soundDialogOpenedCallback} 
                musicDialogOpenedCallback={props.musicDialogOpenedCallback}
            />            
            <div className="content">
                <div className="content-container">
                    <div className="content-header-row">
                        <a href="/about/About" id={AboutSection.ABOUT} onClick={(e) => headerClicked(e, AboutSection.ABOUT)} className="text content-header unselectable">{AboutSection.ABOUT}</a>
                        <a href="/about/Features" id={AboutSection.FEATURES} onClick={(e) => headerClicked(e, AboutSection.FEATURES)} className="text content-header unselectable">{AboutSection.FEATURES}</a>
                        <a href="/about/Why" id={AboutSection.WHY} onClick={(e) => headerClicked(e, AboutSection.WHY)} className="text content-header unselectable">{AboutSection.WHY}</a>
                        <a href="/about/Credits" id={AboutSection.CREDITS} onClick={(e) => headerClicked(e, AboutSection.CREDITS)} className="text content-header unselectable">{AboutSection.CREDITS}</a>
                    </div>
                    {renderAboutContent()}
                </div>
            </div>
            <DotAudioPlayerComponent />
        </div>
    );
}
