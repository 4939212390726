import { logEvent } from 'firebase/analytics';
import { useContext } from 'react';
import { analytics } from '../../App';
import { AppleMusicHelper } from '../../utils/AppleMusicHelper';
import { DotDataContext } from '../DotDataContext/DotDataContext';
import { DotAudioContext, getAudioURL } from './DotAudioContext';

// TODO: Code needs to be changed here to accommodate new songs
export const MAX_SONG_INDEX = 100;

export const shuffleTracklist = (trackList: number[]) => {
    const newTrackList = trackList.slice();
    for (let i = newTrackList.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * i);
        [newTrackList[i], newTrackList[j]] = [newTrackList[j], newTrackList[i]];
    }
    return newTrackList;
}

export const useDotAudioContext = () => {
    const {
        selectedSoundCategory,
        setSelectedSoundCategory,
        audioState, 
        setAudioState, 
        appleMusicState,
        setAppleMusicState,
        playAudio, 
        pauseAudio,
        musicListenedInterval,
        setMusicListenedInterval,
        soundsListenedInterval,
        setSoundsListenedInterval,
        soundsListenedIntervals,
        setSoundsListenedIntervals,
    } = useContext(DotAudioContext);

    const {setLoadingState} = useContext(DotDataContext);

    const setCurrSongIndex = (newIndex: number) => {
        setAudioState({...audioState, currSongIndex: newIndex});
    }

    const setAudioPlaying = (audioPlaying: boolean) => {
        setAudioState({...audioState, audioPlaying: audioPlaying});
    }

    const setAudioURL = (audioURL: string) => {
        setAudioState({...audioState, audioURL: audioURL});
    }

    const setAudioCategory = (category: string) => {
        setAudioState({...audioState, category: category});
    }

    const setVolume = (volume: number) => {
        setAudioState({...audioState, currVolume: volume});
    }

    // TODO: Change behavior to just shuffle.
    const shuffle = async () => {
        if (appleMusicActive()) {
            const appleMusicPlaying = appleMusicState.appleMusicPlaying;
            if (appleMusicPlaying) {
                appleMusicState.musicKit!.pause();
            }

            await appleMusicState.musicKit!.setQueue({playlist: undefined});

            setLoadingState({loadingMessage: "shuffling the playlist...", isLoading: true});
            const songs = await AppleMusicHelper.getAllPlaylistSongs(appleMusicState.musicKit!, appleMusicState.selectedPlaylist!.id);
            const songIds = songs.flatMap(song => song.id);

            // Batch songs to allow a queue > 100
            const playLaterPromises: Promise<void>[] = [];

            while (songIds.length > 0) {
                const songBatch = songIds.splice(0, 100);
                playLaterPromises.push(appleMusicState.musicKit!.playLater({songs: songBatch}));
            }

            await Promise.all(playLaterPromises);
            appleMusicState.musicKit!.player.shuffleMode = 1;
            appleMusicState.musicKit!.player.repeatMode = 2;

            setLoadingState({loadingMessage: "", isLoading: false});

            if (appleMusicPlaying) {
                await appleMusicState.musicKit!.play();
            }
        } else {
            // TODO: Code here needs to be changed to accommodate new songs
            const currTrackList = audioState.category === "Lofi" ? Array.from(Array(329).keys()).map((num, idx) => idx) : audioState.trackList;

            const newTrackList = shuffleTracklist(currTrackList);
            do {
                // TODO: Code here needs to be changed to accommodate new songs
                if (audioState.currSongIndex !== newTrackList[0]) {
                    setAudioState({
                        ...audioState,
                        trackList: [...newTrackList],
                        currSongIndex: 0,
                        audioURL: getAudioURL(audioState.category, newTrackList[0])
                    })
                }
            } 
            while (audioState.currSongIndex === newTrackList[0]);
        }
        logEvent(analytics, "shuffle_music_clicked");
    }

    const setNext = async () => {
        if (appleMusicActive()) {
            const queueLength = appleMusicState.musicKit!.player.queue.length;
            const position = appleMusicState.musicKit!.player.queue.position;
            let newPosition = position + 1;

            if (newPosition >= queueLength) {
                newPosition = 0;
                shuffle();
            }
            await appleMusicState.musicKit!.player.changeToMediaAtIndex(newPosition);

            if (!appleMusicState.appleMusicPlaying) {
                appleMusicState.musicKit!.player.stop();
            }
        } else {
            // TODO: Replace with playNext() from context (?)
            // TODO: Code here needs to be changed to accommodate new songs
            const maxSongs = audioState.category === "Lofi" ? 328 : MAX_SONG_INDEX;
            if (audioState.currSongIndex < maxSongs) {
                const newSongIndex = audioState.currSongIndex + 1;
                setAudioState({
                    ...audioState,
                    currSongIndex: newSongIndex, 
                    audioURL: getAudioURL(audioState.category, audioState.trackList[newSongIndex])
                });           
            } else {
                setAudioState({
                    ...audioState, 
                    currSongIndex: 0, 
                    audioURL: getAudioURL(audioState.category, 0)
                });            
            }
        }
        
        logEvent(analytics, "song_played", {url: audioState.audioURL});
    }

    const setPrevious = async () => {
        if (appleMusicActive()) {
            const newPosition = Math.max(0, appleMusicState.musicKit!.player.queue.position - 1)
            await appleMusicState.musicKit!.player.changeToMediaAtIndex(newPosition);

            if (!appleMusicState.appleMusicPlaying) {
                appleMusicState.musicKit!.player.stop();
            }
        } else {
            // TODO: Code here needs to be changed to accommodate new songs
            const maxSongs = audioState.category === "Lofi" ? 328 : MAX_SONG_INDEX;
            if (audioState.currSongIndex > 0) {
                const newSongIndex = audioState.currSongIndex - 1;
                setAudioState({
                    ...audioState,
                    currSongIndex: newSongIndex, 
                    audioURL: getAudioURL(audioState.category, audioState.trackList[newSongIndex])
                });           
            } else {
                setAudioState({
                    ...audioState, 
                    currSongIndex: maxSongs, 
                    audioURL: getAudioURL(audioState.category, 0)
                });
            }
        }

        logEvent(analytics, "song_played", {url: audioState.audioURL});
    }

    // APPLE MUSIC FUNCTIONS
    const stopAppleMusic = () => {
        if (appleMusicActive()) {
            appleMusicState.musicKit!.stop();
            appleMusicState.musicKit!.setQueue({playlist: undefined});

            setAppleMusicState({
                appleMusicPlaying: false,
                musicKit: appleMusicState.musicKit,
                selectedPlaylist: undefined,
                currentPlayingItem: undefined
            });
        }
    }

    const logoutAppleMusic = async () => {
        stopAppleMusic();
        await appleMusicState.musicKit?.unauthorize();
    }

    const appleMusicActive = () => {
        if (appleMusicState.musicKit) {
            return appleMusicState.musicKit.player.queue.length > 0;
        } else {
            return false;
        }
    }

    return {
        selectedSoundCategory,
        setSelectedSoundCategory,
        audioState,
        appleMusicState,
        setAppleMusicState,
        playAudio,
        pauseAudio,
        setAudioState,
        setVolume,
        setCurrSongIndex,
        setAudioPlaying,
        setAudioURL,
        setAudioCategory,
        shuffle,
        setPrevious,
        setNext,
        musicListenedInterval,
        setMusicListenedInterval,
        soundsListenedInterval,
        setSoundsListenedInterval,
        soundsListenedIntervals,
        setSoundsListenedIntervals,
        stopAppleMusic,
        logoutAppleMusic,
        appleMusicActive
    }
}