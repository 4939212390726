import { Helmet } from 'react-helmet';
import { DotAudioPlayerComponent } from '../../components/DotAudioPlayer/DotAudioPlayer';
import { HeaderComponent } from '../../components/Header/Header';
import './ContactPage.scss';

export const ContactPageComponent = (props: any) => {
    return (
        <div className="contact-container">
            <HeaderComponent
                soundDialogOpenedCallback={props.soundDialogOpenedCallback} 
                musicDialogOpenedCallback={props.musicDialogOpenedCallback}
            />
            <div className="contact-content-container">
                <Helmet>
                    <title>Contact @ Dot Focus</title>
                    <meta 
                        name="description"
                        content="Contact Dot Focus for the latest scoop on the best chill music for focus, and the most calming sounds for work."
                    />
                </Helmet>
                <p className="text">Any help sharing Dot Focus is greatly appreciated.</p>
                <p className="text">I'd love to hear any feedback! Have a request? I will build it!</p>
                <p className="text">You can reach me at the locations below.</p>
                <p className="header-text text">Email</p>
                <p className="text">thedotproductivityapp (at) gmail (dot) com</p>
            </div>
            <DotAudioPlayerComponent/>
        </div>
    );
}