/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState, useContext } from 'react'

import './DotAudioSelectDialog.scss';
import '../../App.scss';
import { MAX_SONG_INDEX, shuffleTracklist, useDotAudioContext } from '../../contexts/DotAudioContext/DotAudioContextCustomHook';
import { AudioState, getAudioURL } from '../../contexts/DotAudioContext/DotAudioContext';
import { ColorPalette, DotDataContext } from '../../contexts/DotDataContext/DotDataContext';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../App';
import { DotNavigationContext, View } from '../../contexts/NavigationContext/NavigationContext';

export type AudioDialogColorPalette = {
  background: string;
  subtitleColor: string;
}

// TODO: Turn all the similar audio dialogs into the same audio dialog component

/**
 * 
 * @param props - dialogClosedCallback, musicDialogOpened
 * @returns 
 */
export const DotAudioSelectDialogComponent = (props: any) => {
  const {
    audioState,
    setAudioState, 
    musicListenedInterval, 
    setMusicListenedInterval,
    appleMusicState
  } = useDotAudioContext();

  const {settingsState} = useContext(DotDataContext);
  const {setView} = useContext(DotNavigationContext);

  const [prevCategory, setPrevCategory] = useState("");
  const [category, setCategory] = useState(audioState.category);

  useEffect(() => {}, [appleMusicState]);

  useEffect(() => {
    const getColorPalette = (): AudioDialogColorPalette => {
      switch (settingsState.backgroundSettings.colorPalette) {
        case ColorPalette.DARK_MODE:
          return {
            background: 'hsl(0, 0%, 20%)',
            subtitleColor: 'hsl(40, 100%, 98%)'
          }
        default:
          return {
            background: 'hsl(210, 100%, 60%)',
            subtitleColor: 'hsl(208, 100%, 89%)',
          }
      }
    }

    const maskElem = document.getElementById('music-dialog-mask');
    if (maskElem) {
      maskElem.style.background = getColorPalette().background;

      const subtitleTextElems = document.getElementsByClassName("music-card-text-subtitle") as HTMLCollectionOf<HTMLElement>;
      for (let i = 0; i < subtitleTextElems.length; i++) {
        const subtitleElem = subtitleTextElems[i];
        if (subtitleElem) {
          subtitleElem.style.color = getColorPalette().subtitleColor;
        }
      }
    }
  }, [settingsState.backgroundSettings.colorPalette, props.musicDialogOpened]);

  useEffect(() => {
    addSelectedCategoryEffect();
  });

  useEffect(() => {
    removePrevCategoryEffect();
    addSelectedCategoryEffect();
  }, [category, prevCategory]);

  const addSelectedCategoryEffect = () => {
    const selectedCategory = category.toLowerCase();
    const selectedCategoryElem = document.getElementById(selectedCategory);
    if (selectedCategoryElem) {
      selectedCategoryElem.classList.add(`${selectedCategory}-selected`);
    }
  }

  const removePrevCategoryEffect = () => {
    const prevSelectedCategory = prevCategory.toLowerCase();
    const prevSelectedCategoryElem = document.getElementById(prevSelectedCategory);

    if (prevSelectedCategoryElem) {
      prevSelectedCategoryElem.classList.remove(`${prevSelectedCategory}-selected`);
    }
  }

  const categoryClicked = (categoryClicked: string) => {
    setPrevCategory(category);
    setCategory(categoryClicked);
  }

  const closeDialog = () => {
    if (audioState.category !== category) {
      // TODO: Code here needs to be changed to accommodate new songs.
      const currTracklist = category === "Lofi" 
        ? Array.from(Array(329).keys()).map((num, idx) => idx) 
        : Array.from(Array(MAX_SONG_INDEX + 1).keys()).map((num, idx) => idx);
      const newTrackList = shuffleTracklist(currTracklist);
      const newAudioState: AudioState = {
        ...audioState,
        currSongIndex: 0,
        category: category,
        audioURL: getAudioURL(category, newTrackList[0]),
        audioPlaying: false,
        trackList: [...newTrackList]
      }
      setAudioState(newAudioState);
      logEvent(analytics, "music_category_selected", {category: `${category}`});

      switch (category) {
        case "Lofi":
          window.history.pushState({view: View.LOFI, audioState: newAudioState}, "", `/${View.LOFI}`);
          setView(View.LOFI);
          break;
        case "Jazz":
          window.history.pushState({view: View.JAZZ, audioState: newAudioState}, "", `/${View.JAZZ}`);
          setView(View.JAZZ);
          break;
        case "Classical":
          window.history.pushState({view: View.CLASSICAL, audioState: newAudioState}, "", `/${View.CLASSICAL}`);
          setView(View.CLASSICAL);
          break;
        case "Soundtrack":
          window.history.pushState({view: View.SOUNDTRACK, audioState: newAudioState}, "", `/${View.SOUNDTRACK}`);
          setView(View.SOUNDTRACK);
          break;
      }

      if (musicListenedInterval) {
        clearInterval(musicListenedInterval);
        setMusicListenedInterval(undefined);
      }
    }

    props.musicDialogClosedCallback();
  }

  const renderDialog = () => {
      if (appleMusicState.selectedPlaylist && props.musicDialogOpened) {
        return (
          <>
            <div id="music-dialog-mask" className="dialog-mask"></div>
            <div className="dialog-container">
              <div className="dialog-header">
                      <svg onClick={() => closeDialog()} xmlns="http://www.w3.org/2000/svg" className="dialog-exit-icon" viewBox="0 0 24 24" fill="none" stroke="hsl(40, 100%, 98%)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                      <p className="text dialog-header-text">Quit Apple Music to play Dot Music stations</p>
              </div>            
            </div>
          </>
        );
      }

      if (props.musicDialogOpened) {
          return ( 
              <>
              <div id="music-dialog-mask" className="dialog-mask"></div>
              <div className="dialog-container">
                  <div className="dialog-header">
                    <svg onClick={() => closeDialog()} xmlns="http://www.w3.org/2000/svg" className="dialog-exit-icon" viewBox="0 0 24 24" fill="none" stroke="hsl(40, 100%, 98%)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                    <p className="text dialog-header-text">now playing: {audioState.category}</p>
                  </div>
                  <div className="dialog-content">
                    <div onClick={() => categoryClicked("Jazz")} className="music-card-row">
                      <div id="jazz" className="music-card-background jazz"></div>
                      <div className="music-card-text-column">
                        <p className="text music-card-text">Jazz</p>
                        <p className="text music-card-text-subtitle">
                          Start focusing with smooth jazz.
                        </p>
                      </div>
                    </div>
                    <div onClick={() => categoryClicked("Lofi")} className="music-card-row">
                      <div id="lofi" className="music-card-background lofi">
                      </div>
                      <div className="music-card-text-column">
                        <p className="text music-card-text">Lofi</p>
                        <p className="text music-card-text-subtitle">
                          Relaxing lofi and chillhop beats.
                        </p>
                      </div>
                    </div>
                    <div onClick={() => categoryClicked("Classical")} className="music-card-row">
                      <div id="classical" className="music-card-background classical">
                      </div>
                      <div className="music-card-text-column">
                        <p className="text music-card-text">Classical</p>
                        <p className="text music-card-text-subtitle">
                          Ageless classics from Bach to Beethoven.
                        </p>
                      </div>
                    </div>
                    <div onClick={() => categoryClicked("Soundtrack")} className="music-card-row">
                      <div id="soundtrack" className="music-card-background soundtrack">
                      </div>
                      <div className="music-card-text-column">
                        <p className="text music-card-text">Soundtrack</p>
                        <p className="text music-card-text-subtitle">
                          For those who need to be truly inspired.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="dialog-footer"></div>
              </div>
              </>
          );       
      } else {
          return null;
      }
  }

  return (
      <>
      {renderDialog()}
      </>
  );
}