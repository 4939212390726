import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { idb } from '../../App';

import '../../App.scss';
import { ColorPalette, DotDataContext, StorageOption } from '../../contexts/DotDataContext/DotDataContext';
import './ToDoList.scss';

export type ToDoItem = {
  value: string;
  complete: boolean;
  id: string;
}

export enum ClearItemState {
  CLEAR_ITEM_NOT_VISIBLE,
  CLEAR_ITEM_VISIBLE,
  CLEAR_ITEM_CLICKED,
}

type ToDoColorPalette = {
  backgroundColor: string;
  itemTextColor: string;
  radioButtonColor: string;
  radioButtonFillColor: string;
  border: string;
}

export const TO_DO_ITEMS_COMPLETED_STAT_KEY = 'to-do-items-completed';

export const ToDoListComponent = () => {
    const [newItemString, setNewItemString] = useState('');
    const [newestItemIndex, setNewestItemIndex] = useState<number>(0);
    const [clearItemState, setClearItemState] = useState<ClearItemState>(ClearItemState.CLEAR_ITEM_NOT_VISIBLE);

    const {
      settingsState,
      toDoItems, setToDoItems,
      toDoItemRefs, setToDoItemRefs
    } = useContext(DotDataContext);

    useEffect(() => {
      const getIdbToDoItems = async () => {
        const allItems = (await idb.db()).getAll('to-do-items');
        allItems.then(response => {
          const localStorageToDoItems = 
            response && response[0] && (response[0] as ToDoItem[]).length > 0 
              ? response[0] as ToDoItem[]
              : toDoItems;

          const refArray = Array.from(Array(localStorageToDoItems.length).keys())
          .map(() => {
            return React.createRef<HTMLInputElement>();
          });

          setToDoItemRefs([...refArray]);
          setToDoItems([...localStorageToDoItems]);
        });
      }

      getIdbToDoItems();
    }, [])

    useEffect(() => {
      /* Things that need to be included: 
        - add border around container: 0.4pt solid grey <-- ALSO FOR NOTES CONTAINER
        - change background color of container to hsl(0, 0%, 20%) 
        - change header text colors to hsl(207, 100%, 95%)
        - item text font color to hsl(207, 100%, 95%)
      */
      const getColorPalette = (): ToDoColorPalette => {
        switch (settingsState.backgroundSettings.colorPalette) {
          case ColorPalette.DARK_MODE: 
            return {
              backgroundColor: 'hsl(0, 0%, 20%)',
              itemTextColor: 'hsl(207, 100%, 95%)',
              radioButtonColor: 'hsl(210, 100%, 60%)',
              radioButtonFillColor: 'hsl(209, 100%, 65%)',
              border: '0.4pt solid grey'
            }
          default: 
            return {
              backgroundColor: 'hsl(207, 100%, 95%)',
              itemTextColor: 'hsl(0, 0%, 0%)',
              radioButtonColor: 'hsl(210, 100%, 60%)',
              radioButtonFillColor: 'hsl(209, 100%, 65%)',
              border: 'none'
            }
        }
      }

      const colorPalette = getColorPalette();

      // inner container is basically the outer container, just with a better margin
      const toDoInnerContainerElem = document.getElementById('to-do-inner-container');
      if (toDoInnerContainerElem) {
        toDoInnerContainerElem.style.border = colorPalette.border;
        toDoInnerContainerElem.style.background = colorPalette.backgroundColor;

        const textElems = toDoInnerContainerElem.getElementsByClassName("text") as HTMLCollectionOf<HTMLElement>;
        for (let i = 0; i < textElems.length; i++) {
          const textElem = textElems[i];
          textElem.style.color = colorPalette.itemTextColor;
        }
      }
    
    }, [settingsState, toDoItems])

    useEffect(() => {
      if (newestItemIndex > 0) {
        const newestItemRef = toDoItemRefs[newestItemIndex];
        
        if (newestItemRef && newestItemRef.current) {
          newestItemRef.current.focus();
        }
      }

      const clearItemTimeout = setTimeout(() => {
        if (clearItemState === ClearItemState.CLEAR_ITEM_CLICKED) {
          setClearItemState(ClearItemState.CLEAR_ITEM_VISIBLE);
        }
      }, 5000);

      return (() => {
        clearTimeout(clearItemTimeout);
      })
    }, [newestItemIndex, toDoItemRefs, clearItemState])

    useEffect(() => {
      if (settingsState.storageOption === StorageOption.KEEP) {
        const updateIdbToDoItems = setTimeout(async () => {
          (await idb.db()).put('to-do-items', toDoItems, 'items');
        }, 300);
  
        return () => clearTimeout(updateIdbToDoItems);
      }
    }, [toDoItems, settingsState.storageOption]);

    // Utility / Helper Functions
    const generateItemId = (string: string) => {
      return string + Math.random() * Math.random() * 100;
    }

    const noExistingNewItem = () => {
      return !toDoItems.find(item => item.value.length === 0);
    }

    const itemLengthZero = (index: number) => {
      return toDoItems[index].value.length === 0;
    }

    const enterPressCheck = (event: any) => {
      return event.key === 'Enter';
    }

    const backspacePressCheck = (event: any) => {
      return event.key === 'Backspace';
    }

    const getClearItemsText = () => {
      switch (clearItemState) {
        case ClearItemState.CLEAR_ITEM_NOT_VISIBLE:
        case ClearItemState.CLEAR_ITEM_VISIBLE:
          return "Clear Items";
        case ClearItemState.CLEAR_ITEM_CLICKED:
          return "Confirm Clear";
      }
    }

    const clearItems = () => {
      setToDoItemRefs([]);
      setToDoItems([]);
      setNewItemString('');
      setNewestItemIndex(0);
    }

    // Main Event Functions
    const onNewItemChange = (value: string) => {
      setNewItemString(value);
    }

    const onItemChange = (index: number, e: ChangeEvent) => {
      const target = e.target as HTMLInputElement;
      const value = target.value;

      const items = toDoItems.slice();
      items[index].value = value;
      setToDoItems([...items]);
    }

    const radioButtonClicked = (index: number) => {
      const toggledComplete = !toDoItems[index].complete;      
      const item = {
        ...toDoItems[index],
        complete: toggledComplete
      }
      const items = toDoItems;
      items[index] = item;

      setToDoItems([...items]);

      if (toggledComplete) {
        const completedItems = localStorage.getItem(TO_DO_ITEMS_COMPLETED_STAT_KEY);
        const newCompletedItems = completedItems ? parseInt(completedItems) + 1: 1;
        localStorage.setItem(TO_DO_ITEMS_COMPLETED_STAT_KEY, newCompletedItems.toString());
      }
    }

    const submitNewItem = (event: any) => {
      if (newItemString.length > 0 && enterPressCheck(event)) {
        const newItem = {
          id: generateItemId(newItemString),
          value: newItemString,
          complete: false,
        }

        const newRef = React.createRef<HTMLInputElement>();


        setNewestItemIndex(toDoItems.length);
        // clear current new item
        setNewItemString('');
        // add new item to array of to do list items
        setToDoItems([...toDoItems, newItem])
        setToDoItemRefs([...toDoItemRefs, newRef]);
      }
    }

    const deleteItem = (index: number) => {
      if (itemLengthZero(index)) {
        const items = toDoItems;
        items.splice(index, 1);

        const refs = toDoItemRefs;
        refs.splice(index, 1);
  
        setNewestItemIndex(-1);
        setToDoItems([...items]);
        setToDoItemRefs([...refs]);
      }
    }

    const insertItem = (index: number) => {
      const newItem: ToDoItem = {
        id: generateItemId('insertedNewItem'),
        value: ``,
        complete: false,
      };
      const itemsBeforeInsert = toDoItems.slice(0, index + 1);
      const itemsAfterInsert = toDoItems.slice(index + 1, toDoItems.length);
      const items = [...itemsBeforeInsert, newItem, ...itemsAfterInsert];

      const newRef = React.createRef<HTMLInputElement>();
      const refsBeforeInsert = toDoItemRefs.slice(0, index + 1);
      const refsAfterInsert = toDoItemRefs.slice(index + 1, toDoItems.length);
      const refs = [...refsBeforeInsert, newRef, ...refsAfterInsert];
      
      setNewestItemIndex(index + 1);
      setToDoItems([...items]);
      setToDoItemRefs([...refs]);
    }

    const modifyList = (event: any, index: number) => {
      if (enterPressCheck(event) && noExistingNewItem()) {
        insertItem(index);
      } else if (backspacePressCheck(event)) {
        deleteItem(index);
      }
    }

    const clearClicked = () => {
      switch (clearItemState) {
        case ClearItemState.CLEAR_ITEM_NOT_VISIBLE:
        case ClearItemState.CLEAR_ITEM_VISIBLE:
          setClearItemState(ClearItemState.CLEAR_ITEM_CLICKED);
          break;
        case ClearItemState.CLEAR_ITEM_CLICKED:
          clearItems();
          setClearItemState(ClearItemState.CLEAR_ITEM_NOT_VISIBLE);
          break;
      }    
    }

    return settingsState.displaySettings.showToDoList ?
        <aside className="to-do-container">
          <div id="to-do-inner-container" className="to-do-items-container-inner">
              <div className="to-do-header-container">
                <p className="text to-do-header-left unselectable">To Do</p>
                {toDoItems.length > 0
                  ? <p onClick={() => clearClicked()} className="text to-do-header-right unselectable">{getClearItemsText()}</p>
                  : null
                }
              </div>
              {toDoItems
                .map((item, index) => {
                    return (
                    <div className="item-container" key={item.id}>
                      {toDoItems[index].complete
                        ? <span onClick={() => radioButtonClicked(index)} id={`radio-button-${index}`} className="radio-button complete"></span> 
                        : <span onClick={() => radioButtonClicked(index)} id={`radio-button-${index}`} className="radio-button"></span>
                      }
                      <input 
                        aria-label={`to do item text input`}
                        defaultValue={item.value} 
                        onKeyDown={event => modifyList(event, index)} 
                        onChange={(e) => onItemChange(index, e)} 
                        onBlur={() => deleteItem(index)}
                        ref={toDoItemRefs[index]}
                        className="text item-text">
                      </input>
                    </div>
                    );
                })}
              <input aria-label="add new item text input" value={newItemString} onKeyPress={event => submitNewItem(event)} onChange={event => onNewItemChange(event.target.value)} type="text" placeholder="+ Add New Item" className="text add-item-row"></input>
          </div>
      </aside>
      : null
}