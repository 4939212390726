import { logEvent } from "firebase/analytics";
import { createContext, useEffect, useState } from "react"
import { analytics } from "../../App";
import { getAudioURL } from "../DotAudioContext/DotAudioContext";
import { shuffleTracklist, useDotAudioContext } from "../DotAudioContext/DotAudioContextCustomHook";

export enum View {
    MAIN = "home",
    ABOUT = "about",
    SETTINGS = "settings",
    NEWS = "news",
    CONTACT = "contact",
    STATS = "stats",
    LOFI = "lofi",
    CLASSICAL = "classical",
    JAZZ = "jazz",
    SOUNDTRACK = "soundtrack",
}

const defaultContextValue = {
    view: View.MAIN,
    setView: (view: View) => {}
}

export const DotNavigationContext = createContext(defaultContextValue);

export const DotNavigator = (props: any) => {
    const {audioState, setAudioState} = useDotAudioContext();
    const [view, setView] = useState(View.MAIN);

    if (window) {
        window.onpopstate = function(e: PopStateEvent) {
            logEvent(analytics, 'pop_state_occurred')
            if (e.state.view && e.state.audioState) {
                if (e.state.audioState.category !== audioState.category) {
                    if (e.state.view === View.LOFI) {
                        // TODO: code here needs to be changed to accommodate new songs
                        const trackList = shuffleTracklist(Array.from(Array(329).keys()).map((num, idx) => idx));
                        setAudioState({
                            ...e.state.audioState,
                            currSongIndex: 0,
                            category: "Lofi",
                            trackList: trackList,
                            audioURL: getAudioURL("Lofi", trackList[0])
                        });
                    } else if (e.state.view === View.MAIN || e.state.view === View.JAZZ || e.state.view === View.CLASSICAL || e.state.view === View.SOUNDTRACK) {
                        const trackList = shuffleTracklist(Array.from(Array(101).keys()).map((num, idx) => idx));
                        setAudioState({
                            ...e.state.audioState,
                            currSongIndex: 0,
                            trackList: trackList,
                            audioURL: getAudioURL(e.state.audioState.category, trackList[0])
                        });
                    }
                }
                setView(e.state.view);
            }
        }
    }

    useEffect(() => {
        if (window.location.pathname) {
            switch (window.location.pathname) {
                case "/home":
                    window.history.replaceState({view: View.MAIN, audioState: audioState}, "", `/${View.MAIN}`);
                    setView(View.MAIN);
                    break;
                case "/about/About":
                    window.history.replaceState({view: View.ABOUT, audioState: audioState}, "", `/about/About`);
                    setView(View.ABOUT);
                    break;
                case "/about/Features": 
                    window.history.replaceState({view: View.ABOUT, audioState: audioState}, "", `/about/Features`);
                    setView(View.ABOUT);
                    break;
                case "/about/Why":
                    window.history.replaceState({view: View.ABOUT, audioState: audioState}, "", `/about/Why`);
                    setView(View.ABOUT);
                    break;
                case "/about/Credits":
                    window.history.replaceState({view: View.ABOUT, audioState: audioState}, "", `/about/Credits`);
                    setView(View.ABOUT);
                    break;
                case "/settings":
                    window.history.replaceState({view: View.SETTINGS, audioState: audioState}, "", `/${View.SETTINGS}`);
                    setView(View.SETTINGS);
                    break;
                case "/contact":
                    window.history.replaceState({view: View.CONTACT, audioState: audioState}, "", `/${View.CONTACT}`);
                    setView(View.CONTACT);
                    break;
                case "/stats": 
                    window.history.replaceState({view: View.STATS, audioState: audioState}, "", `/${View.STATS}`);
                    setView(View.STATS);
                    break;
                case "/lofi":
                    window.history.replaceState({view: View.LOFI, audioState: audioState}, "", `/${View.LOFI}`);
                    setView(View.LOFI);
                    break;
                case "/jazz":
                    window.history.replaceState({view: View.JAZZ, audioState: audioState}, "", `/${View.JAZZ}`);
                    setView(View.JAZZ);
                    break;
                case "/classical":
                    window.history.replaceState({view: View.CLASSICAL, audioState: audioState}, "", `/${View.CLASSICAL}`);
                    setView(View.CLASSICAL);
                    break;
                case "/soundtrack":
                    window.history.replaceState({view: View.SOUNDTRACK, audioState: audioState}, "", `/${View.SOUNDTRACK}`);
                    setView(View.SOUNDTRACK);
                    break;
                default:
                    window.history.replaceState({view: View.MAIN, audioState: audioState}, "", `/${View.MAIN}`);
                    setView(View.MAIN);
                    break;
            }
        }
    }, [])

    useEffect(() => {
        if (view !== View.ABOUT && view !== View.LOFI && view !== View.CLASSICAL && view !== View.JAZZ && view !== View.SOUNDTRACK) {
            logEvent(analytics, "page_view");
        }
    }, [view])

    return (
        <DotNavigationContext.Provider value={{view, setView}}>
            {props.children}
        </DotNavigationContext.Provider>
    );
}