import { useEffect, useState, useContext } from "react";
import { ColorPalette, DotDataContext, PomodoroStatus} from "../../contexts/DotDataContext/DotDataContext";
import { AudioDialogColorPalette } from "../DotAudioSelectDialog/DotAudioSelectDialog";
import "./PomodoroSettingsDialog.scss";

export const PomodoroSettingsDialogComponent = (props: any) => {
    const {pomodoroState, setPomodoroState} = useContext(DotDataContext);
    const {settingsState} = useContext(DotDataContext);

    const [pomodoroLength, setPomodoroLength] = useState<number>(pomodoroState.pomodoroTimerLength / 60);
    const [shortBreak, setShortBreak] = useState<number>(pomodoroState.shortBreakLength / 60);
    const [longBreak, setLongBreak] = useState<number>(pomodoroState.longBreakLength / 60);
    const [cycles, setCycles] = useState<number>(pomodoroState.totalCycles);
    const [autoBeginBreaks, setAutoBeginBreaks] = useState(pomodoroState.autoBeginBreaks);
    const [autoBeginTimer, setAutoBeginTimer] = useState(pomodoroState.autoBeginTimer);
    const [pauseSoundsOnBreak, setPauseSoundsOnBreak] = useState(pomodoroState.pauseSoundsOnBreak);

    useEffect(() => {
        const getColorPalette = (): AudioDialogColorPalette => {
            switch (settingsState.backgroundSettings.colorPalette) {
              case ColorPalette.DARK_MODE:
                return {
                  background: 'hsl(0, 0%, 20%)',
                  subtitleColor: 'hsl(40, 100%, 98%)'
                }
              default:
                return {
                  background: 'hsl(210, 100%, 60%)',
                  subtitleColor: 'hsl(208, 100%, 89%)',
                }
            }
          }
          
          const maskElem = document.getElementById('pomodoro-dialog-mask');
          if (maskElem) {
              maskElem.style.backgroundColor = getColorPalette().background;
          }
    }, [])

    useEffect(() => {}, [pomodoroState, pomodoroLength, shortBreak, longBreak, cycles]);

    const closeDialog = () => {
        props.closeDialogCallback();
    }

    const saveSettings = () => {
        const pomodoroSeconds = pomodoroLength * 60;
        const smallBreakSeconds = shortBreak * 60;
        const largeBreakSeconds = longBreak * 60;

        if (
            pomodoroSeconds === pomodoroState.pomodoroTimerLength && 
            smallBreakSeconds === pomodoroState.shortBreakLength &&
            largeBreakSeconds === pomodoroState.longBreakLength &&
            cycles === pomodoroState.totalCycles &&
            autoBeginBreaks === pomodoroState.autoBeginBreaks &&
            autoBeginTimer === pomodoroState.autoBeginTimer &&
            pauseSoundsOnBreak == pomodoroState.pauseSoundsOnBreak
        ) {
            props.closeDialogCallback();
            return;
        }

        localStorage.setItem('WORKING_TIMER_SECONDS', `${pomodoroSeconds}`);
        localStorage.setItem('SMALL_BREAK_TIMER_SECONDS', `${smallBreakSeconds}`);
        localStorage.setItem('LARGE_BREAK_TIMER_SECONDS', `${largeBreakSeconds}`);
        localStorage.setItem('CYCLES', `${cycles}`);
        localStorage.setItem('AUTO_BEGIN_BREAKS', autoBeginBreaks ? 'true' : 'false');
        localStorage.setItem('AUTO_BEGIN_TIMER', autoBeginTimer ? 'true' : 'false');
        localStorage.setItem('PAUSE_SOUNDS_ON_BREAK', pauseSoundsOnBreak ? 'true' : 'false');

        let secondsRemaining: number;
        switch (pomodoroState.pomodoroStatus) {
            case PomodoroStatus.LARGE_BREAK:
                const timeElapsed1 = pomodoroState.longBreakLength - pomodoroState.secondsRemaining;
                secondsRemaining = Math.max(pomodoroState.secondsRemaining % 60, largeBreakSeconds - timeElapsed1);
                break;
            case PomodoroStatus.SMALL_BREAK:
                const timeElapsed2 = pomodoroState.shortBreakLength - pomodoroState.secondsRemaining;
                secondsRemaining = Math.max(pomodoroState.secondsRemaining % 60, smallBreakSeconds - timeElapsed2);
                break;
            default:
                const timeElapsed3 = pomodoroState.pomodoroTimerLength - pomodoroState.secondsRemaining;
                secondsRemaining = Math.max(pomodoroState.secondsRemaining % 60, pomodoroSeconds - timeElapsed3);
                break;
        }

        setPomodoroState({
            secondsRemaining: secondsRemaining,
            timerOn: pomodoroState.timerOn,
            totalCycles: cycles,
            currentCycle: pomodoroState.currentCycle,
            pomodoroStatus: pomodoroState.pomodoroStatus,
            longBreakLength: largeBreakSeconds,
            shortBreakLength: smallBreakSeconds,
            pomodoroTimerLength: pomodoroSeconds,
            autoBeginBreaks: autoBeginBreaks,
            autoBeginTimer: autoBeginTimer,
            pauseSoundsOnBreak: pauseSoundsOnBreak
        });
        props.closeDialogCallback();
    }

    return (
        (<>
            <div id="pomodoro-dialog-mask" className="pomodoro-settings-dialog-mask">
        
            </div>
            <div className="pomodoro-settings-dialog">
                <div className="pomodoro-settings-header-row">
                    <svg onClick={() => closeDialog()} xmlns="http://www.w3.org/2000/svg" className="dialog-exit-icon" viewBox="0 0 24 24" fill="none" stroke="hsl(40, 100%, 98%)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                    <p className="text pomodoro-settings-header">Pomodoro Settings</p>
                </div>
                <div className="pomodoro-settings-text">Pomodoro Length</div>
                <div className="pomodoro-settings-row">
                    <div onClick={() => pomodoroLength - 1 > 0 ? setPomodoroLength(pomodoroLength - 1) : null} className="operator-button">
                        <svg className="operator-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><line x1="5" y1="12" x2="19" y2="12"></line></svg>
                    </div>
                    <div className="pomodoro-settings-text larger unselectable">{pomodoroLength} minutes</div>
                    <div onClick={() => setPomodoroLength(pomodoroLength + 1)}className="operator-button">
                        <svg className="operator-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
                    </div>
                </div>

                <div className="pomodoro-settings-text unselectable">Short Break</div>
                <div className="pomodoro-settings-row">
                    <div onClick={() => shortBreak - 1 > 0 ? setShortBreak(prevState => prevState - 1) : null}className="operator-button">
                        <svg className="operator-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><line x1="5" y1="12" x2="19" y2="12"></line></svg>
                    </div>
                    <div className="pomodoro-settings-text larger unselectable">{shortBreak} minutes</div>
                    <div onClick={() => setShortBreak(prevState => prevState + 1)}className="operator-button">
                        <svg className="operator-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
                    </div>
                </div>

                <div className="pomodoro-settings-text unselectable">Long Break</div>
                <div className="pomodoro-settings-row">
                    <div onClick={() => longBreak - 1 > 0 ? setLongBreak(prevState => prevState - 1) : null} className="operator-button">
                        <svg className="operator-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><line x1="5" y1="12" x2="19" y2="12"></line></svg>
                    </div>
                    <div className="pomodoro-settings-text larger unselectable">{longBreak} minutes</div>
                    <div onClick={() => setLongBreak(prevState => prevState + 1)} className="operator-button">
                        <svg className="operator-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
                    </div>
                </div>

                <div className="pomodoro-settings-text unselectable">Cycles before Long Break</div>
                <div className="pomodoro-settings-row">
                    <div onClick={() => cycles - 1 > 0 ? setCycles(prevState => prevState - 1) : null}className="operator-button">
                        <svg className="operator-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><line x1="5" y1="12" x2="19" y2="12"></line></svg>
                    </div>
                    <div className="pomodoro-settings-text larger unselectable">{cycles} cycles</div>
                    <div onClick={() => setCycles(prevState => prevState + 1)}className="operator-button">
                        <svg className="operator-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
                    </div>
                </div>

                <div className="pomodoro-settings-row-radio-button">
                    { autoBeginBreaks
                        ? <div onClick={() => setAutoBeginBreaks(false)} className="radio-button-complete"></div>
                        : <div onClick={() => setAutoBeginBreaks(true)} className="radio-button"></div>
                    }
                    <p className="text pomodoro-settings-text unselectable">Automatically begin breaks</p>
                </div>

                <div className="pomodoro-settings-row-radio-button">
                    { autoBeginTimer
                        ? <div onClick={() => setAutoBeginTimer(false)} className="radio-button-complete"></div>
                        : <div onClick={() => setAutoBeginTimer(true)} className="radio-button"></div>
                    }                    
                    <p className="text pomodoro-settings-text unselectable">Automatically begin pomodoro</p>
                </div>

                <div className="pomodoro-settings-row-radio-button">
                    { pauseSoundsOnBreak
                        ? <div onClick={() => setPauseSoundsOnBreak(false)} className="radio-button-complete"></div>
                        : <div onClick={() => setPauseSoundsOnBreak(true)} className="radio-button"></div>
                    }                    
                    <p className="text pomodoro-settings-text unselectable">Pause sounds during breaks</p>
                </div>

                <div onClick={() => saveSettings()} className="save-button centered">
                    <p className="text button-text unselectable">Save</p>
                </div>
            </div>
        </>)
    )
}