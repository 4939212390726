import { logEvent } from 'firebase/analytics';
import React, { useEffect, useState } from 'react';
import { analytics } from '../../App';
import { AppleMusicResource } from '../../utils/AppleMusicHelper';
import { MAX_SONG_INDEX, shuffleTracklist } from './DotAudioContextCustomHook';

export const MUSIC_TIME_LISTENED_STAT_KEY = 'music-time-listened';

export type AudioState = {
    selectedSoundTracks: string[];
    audioPlaying: boolean;
    currSongIndex: number;
    audioURL: string;
    category: string;
    trackList: number[];
    currVolume: number;
    errorPlaying: boolean;
}

export type AppleMusicAudioState = {
    appleMusicPlaying: boolean;
    musicKit: MusicKit.MusicKitInstance | undefined;
    selectedPlaylist: AppleMusicResource | undefined;
    currentPlayingItem: MusicKit.MediaItem | undefined;
}

export const BASE_AUDIO_URL = "https://dot-focus.b-cdn.net"

export const getAudioURL = (category: string, index: number) => {
    return `${BASE_AUDIO_URL}/${category}/${category}-${index}.mp3`;
}

export const getSoundURL = (trackId: string) => {
    return `${BASE_AUDIO_URL}/${trackId}.mp3`
}

// TODO: Code here needs to be changed to accommodate new songs (Not necessary though...)
let timeout: NodeJS.Timeout | undefined;
let timeouts: {trackId: string, interval: NodeJS.Timeout}[] = [];

let musicKit: MusicKit.MusicKitInstance | undefined;
let appleMusicResource: AppleMusicResource | undefined;
let mediaItem: MusicKit.MediaItem | undefined;

const currSongIndex = 0;
let songCategory: string;
let trackList: number[];
switch (window.location.pathname) {
    case "/jazz":
        songCategory = "Jazz";
        trackList = shuffleTracklist(Array.from(Array(101).keys()).map((num, idx) => idx));
        break;
    case "/lofi":
        songCategory = "Lofi";
        trackList = shuffleTracklist(Array.from(Array(329).keys()).map((num, idx) => idx));
        break;
    case "/classical":
        songCategory = "Classical";
        trackList = shuffleTracklist(Array.from(Array(101).keys()).map((num, idx) => idx));
        break;
    case "/soundtrack":
        songCategory = "Soundtrack";
        trackList = shuffleTracklist(Array.from(Array(101).keys()).map((num, idx) => idx));
        break;
    default:
        songCategory = "Lofi";
        trackList = shuffleTracklist(Array.from(Array(329).keys()).map((num, idx) => idx));
        break;
}

const defaultContextValue = {
    selectedSoundCategory: "",
    setSelectedSoundCategory: (category: string) => {},
    audioState: {
        selectedSoundTracks: [""],
        audioPlaying: false,
        currSongIndex: currSongIndex,
        category: songCategory,
        audioURL: getAudioURL(songCategory, trackList[currSongIndex]),
        // TODO: Code here needs to be changed to accommodate new songs
        trackList: trackList,
        currVolume: 30,
        errorPlaying: false,
    },
    setAudioState: (state: AudioState) => {},
    playAudio: () => {},
    pauseAudio: () => {},
    // intervals for stats
    musicListenedInterval: timeout,
    setMusicListenedInterval: (timeout: NodeJS.Timeout | undefined) => {},
    soundsListenedInterval: timeout,
    setSoundsListenedInterval: (timeout: NodeJS.Timeout | undefined) => {},
    soundsListenedIntervals: timeouts,
    setSoundsListenedIntervals: (timeouts: ({trackId: string, interval: NodeJS.Timeout}[])) => {},
    appleMusicState: {
        appleMusicPlaying: false,
        musicKit: musicKit,
        selectedPlaylist: appleMusicResource,
        currentPlayingItem: mediaItem
    },
    setAppleMusicState: (state: AppleMusicAudioState) => {}
}

export const DotAudioContext = React.createContext(defaultContextValue);

export const DotAudioProvider = (props: any) => {
    const [appleMusicState, setAppleMusicState] = useState<AppleMusicAudioState>({
        appleMusicPlaying: false,
        musicKit: musicKit,
        selectedPlaylist: appleMusicResource,
        currentPlayingItem: mediaItem,
    });
    const [selectedSoundCategory, setSelectedSoundCategory] = useState("");
    const [audioState, setAudioState] = useState(defaultContextValue.audioState);
    const [musicListenedInterval, setMusicListenedInterval] = useState<NodeJS.Timeout>();
    const [soundsListenedInterval, setSoundsListenedInterval] = useState<NodeJS.Timeout>();
    const [soundsListenedIntervals, setSoundsListenedIntervals] = useState<{trackId: string, interval: NodeJS.Timeout}[]>([]);

    useEffect(() => {
        const audioElement = document.getElementById("audio") as HTMLAudioElement;
        const handleAudioError = (e: Event) => {
            setAudioState({...audioState, errorPlaying: true, audioPlaying: false});
            alert('Audio failed to load. Please refresh the page.');
        }

        audioElement.addEventListener('error', handleAudioError);  

        return () => {
            audioElement.removeEventListener('error', handleAudioError);
        }
    }, []);

    useEffect(() => {
        if (appleMusicState.musicKit) {
            appleMusicState.musicKit.addEventListener('mediaItemDidChange', () => {
                setAppleMusicState({...appleMusicState, currentPlayingItem: appleMusicState.musicKit!.player.nowPlayingItem});
            })
        }

        return () => {
            appleMusicState.musicKit?.removeEventListener('mediaItemDidChange');
        }
    }, [appleMusicState]);

    useEffect(() => {}, [musicListenedInterval]);
    
    useEffect(() => {
        const audio = document.getElementById("audio") as HTMLAudioElement;
        audio.volume = audioState.currVolume / 100;
        audioState.audioPlaying ? audio.play() : audio.pause();
    }, [audioState]);

    const playAudio = async () => {
        if (appleMusicActive()) {
            await appleMusicState.musicKit!.play();
            setAppleMusicState({...appleMusicState, appleMusicPlaying: true, currentPlayingItem: appleMusicState.musicKit!.player.nowPlayingItem});
        } else {
            const audio = document.getElementById("audio") as HTMLAudioElement;
            if (!audioState.errorPlaying) {
                setAudioState({...audioState, audioPlaying: true});
                audio.play();
                logEvent(analytics, "play_music_clicked");
            } else {
                alert('Failed to load music. Please refresh the page.');
            }
        }

        const musicTimeInterval = setInterval(() => {
            const musicTimeListened = localStorage.getItem(MUSIC_TIME_LISTENED_STAT_KEY);
            const categoryTimeListened = localStorage.getItem(`${audioState.category}-${MUSIC_TIME_LISTENED_STAT_KEY}`);
            const newMusicTimeListened = musicTimeListened ? parseInt(musicTimeListened) + 1 : 1;
            const newCategoryTimeListened = categoryTimeListened ? parseInt(categoryTimeListened) + 1 : 1;
            localStorage.setItem(MUSIC_TIME_LISTENED_STAT_KEY, newMusicTimeListened.toString());
            localStorage.setItem(`${audioState.category}-${MUSIC_TIME_LISTENED_STAT_KEY}`, newCategoryTimeListened.toString());
        }, 60000);
        setMusicListenedInterval(musicTimeInterval);
    }

    const pauseAudio = async () => {
        if (appleMusicActive()) {
            await appleMusicState.musicKit!.pause();
            setAppleMusicState({...appleMusicState, appleMusicPlaying: false});
        } else {
            const audio = document.getElementById("audio") as HTMLAudioElement;
            setAudioState({...audioState, audioPlaying: false});
            audio.pause();
            logEvent(analytics, "pause_music_clicked");
        }

        if (musicListenedInterval) {
            clearInterval(musicListenedInterval);
            setMusicListenedInterval(undefined);
        }
    }

    const loadAudio = () => {
        const audio = document.getElementById("audio") as HTMLAudioElement;
        audio.load();
    }

    // TODO: Code here needs to be changed to accommodate new songs
    const playNext = () => {
        const maxSongs = audioState.category === "Lofi" ? 328 : MAX_SONG_INDEX;
        if (audioState.currSongIndex < maxSongs) {
            const newSongIndex = audioState.currSongIndex + 1;
            setAudioState({
                ...audioState, 
                currSongIndex: newSongIndex, 
                audioURL: getAudioURL(audioState.category, audioState.trackList[newSongIndex])
            })
        } else {
            setAudioState({
                ...audioState, 
                currSongIndex: 0, 
                audioURL: getAudioURL(audioState.category, audioState.trackList[0])
            });            
        }
        loadAudio();
        logEvent(analytics, "song_played", {url: audioState.audioURL});
    }

    const appleMusicActive = () => {
        if (appleMusicState.musicKit) {
            return appleMusicState.musicKit.player.queue.length > 0;
        } else {
            return false;
        }
    }

    return (
        <DotAudioContext.Provider value={{
            selectedSoundCategory,
            setSelectedSoundCategory,
            audioState, 
            setAudioState, 
            playAudio, 
            pauseAudio,
            musicListenedInterval,
            setMusicListenedInterval,
            soundsListenedInterval,
            setSoundsListenedInterval,
            soundsListenedIntervals,
            setSoundsListenedIntervals,
            appleMusicState,
            setAppleMusicState
        }}>
            <audio src={audioState.audioURL} id="audio" onEnded={() => playNext()}/>
            <audio id="alert-audio" src={`${BASE_AUDIO_URL}/Alert.mp3`}/>
            {props.children}
        </DotAudioContext.Provider>
    );
}