import { logEvent } from 'firebase/analytics';
import React, { useEffect } from 'react';
import { analytics } from '../../App';

import { useDotAudioContext } from '../../contexts/DotAudioContext/DotAudioContextCustomHook';
import { AppleMusicHelper } from '../../utils/AppleMusicHelper';
import { generateJwtToken } from '../../utils/AppleMusicTokenGenerator';
import './DotAudioSelectBlob.scss';

// TODO: Create interface for props on all components
/**
 * 
 * @param props - musicDialogOpenedCallback, soundDialogOpenedCallback(), appleMusicDialogOpenedCallback()
 * @returns 
 */
export const DotAudioSelectBlobComponent = (props: any) => {
    const {audioState, appleMusicActive, appleMusicState, setAppleMusicState} = useDotAudioContext();

    useEffect(() => {
        const blobElement = document.getElementById('blob');
        const blobInsideElement = document.getElementById('blob-inside');

        if (blobElement && blobInsideElement) {
            blobElement.style.animation = "blobber 40s 0s linear infinite, cobler 40s 0s linear infinite alternate";
            blobInsideElement.style.animation = "blobber 40s 0s linear infinite, wobbler 40s 0s linear infinite alternate";
        }
    }, [])

    useEffect(() => {}, [appleMusicState]);

    const openMusicDialog = () => {
        props.musicDialogOpenedCallback();
    }

    const openSoundsDialog = () => {
        props.soundDialogOpenedCallback();
    }

    // TODO: is there a way to pass an AppleMusicHelper "scope" (?)
    const openAppleMusicDialog = () => {
        logEvent(analytics, "apple_music_dialog_opened");
        if (!appleMusicState.musicKit) {
            const musicKitScript = document.createElement('script');
            musicKitScript.src = "https://js-cdn.music.apple.com/musickit/v1/musickit.js"
            document.head.appendChild(musicKitScript);
    
            window.document.addEventListener('musickitloaded', () => {
                try {
                    const musicKit = MusicKit.configure({
                        developerToken: generateJwtToken()
                    });
    
                    if (musicKit !== undefined) {
                        musicKit.player.volume = audioState.currVolume / 100;
    
                        if (musicKit.isAuthorized) {
                            props.appleMusicDialogOpenedCallback();
                        } else {
                            AppleMusicHelper.appleMusicAuthorization(musicKit)?.then(() => {
                                props.appleMusicDialogOpenedCallback();
                            });
                        }
        
                        setAppleMusicState({
                            appleMusicPlaying: false,
                            musicKit: musicKit,
                            selectedPlaylist: undefined,
                            currentPlayingItem: undefined
                        });
                    }
                } catch (error) {
                    alert('Apple Music failed to configure. Try again, and refresh the page if problems persist.')
                }
            });
        } else {
            if (appleMusicState.musicKit.isAuthorized) {
                props.appleMusicDialogOpenedCallback();
            } else {
                AppleMusicHelper.appleMusicAuthorization(appleMusicState.musicKit)?.then(() => {
                    props.appleMusicDialogOpenedCallback();
                });
            }
        }
    }

    const fetchStationTitle = () => {
        if (appleMusicActive()) {
            if (appleMusicState.selectedPlaylist) {
                return appleMusicState.selectedPlaylist.attributes.name;
            } else {
                return "Apple Playlist"
            }
        } else {
            return audioState.category;
        }
    }
  
    const fetchStationArtist = () => {
        if (appleMusicActive() && appleMusicState.currentPlayingItem) {
            return appleMusicState.currentPlayingItem.title;
        } else {
            return "Music Station";
        }
    }

    return (
        <div id="blob" className="blob">
            <div id="blob-inside" className="blob-inside"></div>
            <div className="blob-text-container">
                <div className="text blob-text-title">{fetchStationTitle()}</div>
                <div className="text blob-text-subtitle">{fetchStationArtist()}</div>
                <div className="blob-row">
                    <div className="new-song-button">
                        <div onClick={() => openMusicDialog()} className="text button-text">Music</div>
                    </div>
                    <div className="new-song-button">
                        <div onClick={() => openSoundsDialog()} className="text button-text">Sounds</div>
                    </div>
                </div>
                <div className="blob-row">
                    <div className="third-party-music-button">
                        <div onClick={() => {openAppleMusicDialog()}} className="text button-text">Apple Music (beta)</div>
                    </div>
                </div>
            </div>
        </div>
    );
}